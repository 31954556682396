import { isAnyAttributeSetInCampaign } from "../isAnyAttributeSet";

/**
 * @param {object} params
 * @param {import("../../../types/Campaign").Campaign} params.campaign
 * @returns {boolean}
 */
export function isCampaignDateButtonEnabledInSearch({ campaign }) {
  const value = !isAnyAttributeSetInCampaign({
    campaign,
    attributeNamesToCheck: ["checkin", "checkout"],
  });
  return value;
}
