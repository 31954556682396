import {
  Modal,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { SearchModalContent } from "./Content";

/**
 * @typedef {object} Props
 * @property {() => void} submit
 * @property {boolean} isOpen
 * @property {() => void} onClose
 * @property {import("react-hook-form").UseFormReturn<import("../Search/Handler").SearchFormValues>} form
 * @property {import("@raiden/library-ui/types/Campaign").Campaign | undefined} [campaign]
 * @property {import("@raiden/library-ui/types/Region").Region} [region]
 * @property {import("@raiden/library-ui/types/Departement").Departement} [department]
 */
/**
 * @param {Props} props
 */
export function SearchModal({
  submit,
  isOpen,
  onClose,
  form,
  campaign,
  region,
  department,
}) {
  const [step, setStep] = useState(0);

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    if (!isMobile) {
      onClose();
    }
  }, [isMobile, onClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      size="full">
      <ModalOverlay />

      <ModalContent sx={{ "&": { minH: "100dvh" } }}>
        <SearchModalContent
          step={step}
          setStep={setStep}
          submit={submit}
          onClose={onClose}
          form={form}
          campaign={campaign}
          region={region}
          department={department}
        />
      </ModalContent>
    </Modal>
  );
}
