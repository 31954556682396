import { Box, Tag } from "@chakra-ui/react";
import { useMemo } from "react";

/**
 * @typedef {object} Props
 * @property {string[]} appliedFilters
 * @property {string[]} filtersName - The filters that this badge should count
 * @property {React.ReactNode} children
 * @property {Record<string, () => number>} [resolvers]
 */
/**
 * @param {Props} props
 */
export function FiltersBadgePublic({
  appliedFilters,
  filtersName,
  resolvers,
  children,
}) {
  const appliedFiltersCount = useMemo(() => {
    let filtersCount = appliedFilters.filter(
      (filter) => filtersName.includes(filter) && !resolvers?.[filter],
    ).length;
    if (resolvers) {
      filtersCount += Object.values(resolvers).reduce(
        (acc, resolver) => acc + resolver(),
        0,
      );
    }
    return filtersCount;
  }, [appliedFilters, filtersName, resolvers]);

  return (
    <Box display="inline" position="relative">
      {children}

      <Tag
        position="absolute"
        top="0"
        right="-.5rem"
        transform={`translateY(-50%) scale(${appliedFiltersCount > 0 ? 1 : 0})`}
        opacity={appliedFiltersCount > 0 ? 1 : 0}
        transition="all 0.3s"
        pointerEvents="none"
        borderRadius="full"
        justifyContent="center"
        backgroundColor="brandSecondary.500"
        color="#fff">
        {appliedFiltersCount}
      </Tag>
    </Box>
  );
}
