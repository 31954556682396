import { createCalendar, parseDate } from "@internationalized/date";
import { useRangeCalendar } from "@react-aria/calendar";
import { useRangeCalendarState } from "@react-stately/calendar";
import { useEffect, useRef } from "react";
import useLocale from "../../hooks/useLocale";

/**
 * @typedef {object} Props
 * @property {import("@internationalized/date").DateDuration} visibleDuration
 * @property {string} [focusedDate]
 * @property {string} [minCheckin] yyyy-MM-dd
 * @property {string} [maxCheckout] yyyy-MM-dd
 * @property {(params: {state: import("@react-stately/calendar").RangeCalendarState, calendar: import("@react-aria/calendar").CalendarAria, ref: import("react").MutableRefObject<HTMLDivElement | null>}) => import("react").ReactElement} render
 */
/**
 * @param {Omit<import("../ReactHookForm/FormControlRHF").FieldProps<{ start: string, end: string }>, "ref"> & Props} props
 */
export function StandAloneDateRangePicker({
  name,
  value,
  onChange,
  visibleDuration,
  focusedDate,
  minCheckin,
  maxCheckout,
  render,
}) {
  const ref = useRef(/** @type {HTMLDivElement | null} */ (null));

  const { locale, defaultLocale } = useLocale();

  const state = useRangeCalendarState({
    value: {
      start: parseDate(value.start),
      end: parseDate(value.end),
    },
    locale: locale ?? defaultLocale,
    defaultValue: undefined,
    createCalendar,
    visibleDuration,
    minValue: minCheckin ? parseDate(minCheckin) : undefined,
    maxValue: maxCheckout ? parseDate(maxCheckout) : undefined,
    onChange: (value) => {
      onChange({
        target: {
          name,
          value: {
            start: value.start.toString(),
            end: value.end.toString(),
          },
        },
      });
    },
  });

  useEffect(() => {
    if (focusedDate) {
      state.setFocusedDate(parseDate(focusedDate));
      state.setFocused(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedDate]);

  let calendar = useRangeCalendar({}, state, ref);

  return render({
    state,
    calendar,
    ref,
  });
}
