import dayjs from "dayjs";

export function getAvailableMonths() {
  /** @type {string[]} */
  const availableMonths = [];
  for (let i = 0; i < 12; i++) {
    const month = dayjs().add(i, "month").set("date", 1).format("YYYY-MM-DD");
    availableMonths.push(month);
  }
  return availableMonths;
}
