import { Box, Button, HStack, Text } from "@chakra-ui/react";
import { forwardRef, useMemo } from "react";
import { defineMessage, useIntl } from "react-intl";

/** @type {{value: "" | number, label: import("react-intl").MessageDescriptor}[]} */
const OPTIONS = [
  {
    value: "",
    label: defineMessage({ defaultMessage: "Indifférent" }),
  },
  {
    value: 1,
    label: defineMessage({ defaultMessage: "1" }),
  },
  {
    value: 2,
    label: defineMessage({ defaultMessage: "2" }),
  },
  {
    value: 3,
    label: defineMessage({ defaultMessage: "3" }),
  },
  {
    value: 4,
    label: defineMessage({ defaultMessage: "4" }),
  },
  {
    value: 5,
    label: defineMessage({ defaultMessage: "5" }),
  },
  {
    value: 6,
    label: defineMessage({ defaultMessage: "6" }),
  },
  {
    value: 7,
    label: defineMessage({ defaultMessage: "7" }),
  },
  {
    value: 8,
    label: defineMessage({ defaultMessage: "8" }),
  },
  {
    value: 9,
    label: defineMessage({ defaultMessage: "9" }),
  },
  {
    value: 10,
    label: defineMessage({ defaultMessage: "10+" }),
  },
];

export const NumberPicker = forwardRef(
  /**
   * @typedef {import("@raiden/library-ui/components/ReactHookForm/FormControlRHF").FieldProps<number | "">} FieldProps
   */
  /**
   * @param {FieldProps} props
   */
  function NumberPicker({ name, value, onChange, isDisabled }, ref) {
    const intl = useIntl();

    const currentOption = useMemo(() => {
      return OPTIONS.find((option) => option.value === value);
    }, [value]);

    const previousOption = useMemo(() => {
      if (!currentOption) {
        return undefined;
      }
      return OPTIONS[OPTIONS.indexOf(currentOption) - 1];
    }, [currentOption]);

    const nextOption = useMemo(() => {
      if (!currentOption) {
        return undefined;
      }
      return OPTIONS[OPTIONS.indexOf(currentOption) + 1];
    }, [currentOption]);

    return (
      <Box sx={{ containerType: "inline-size" }} overflow="hidden">
        <Box
          sx={{
            [`@container (width >= 567px)`]: {
              "&": {
                display: "none",
              },
            },
          }}>
          <HStack justify="space-between" spacing=".5rem">
            <Button
              onClick={() => {
                onChange({
                  target: { name, value: previousOption?.value ?? "" },
                });
              }}
              isDisabled={!previousOption || isDisabled}
              variant="outline"
              borderRadius="full">
              -
            </Button>

            <Text
              opacity={isDisabled ? 0.5 : 1}
              fontSize="1rem"
              fontWeight={400}
              lineHeight="1.3125rem"
              textAlign="center"
              minW="5rem"
              color={value > 0 ? undefined : "gray.500"}>
              {currentOption && intl.formatMessage(currentOption.label)}
            </Text>

            <Button
              onClick={() => {
                onChange({ target: { name, value: nextOption?.value ?? "" } });
              }}
              isDisabled={!nextOption || isDisabled}
              variant="outline"
              borderRadius="full">
              +
            </Button>
          </HStack>
        </Box>

        <HStack
          sx={{
            [`@container (width < 567px)`]: {
              "&": {
                display: "none",
              },
            },
          }}>
          {OPTIONS.map((option) => (
            <Button
              key={option.value}
              ref={ref}
              onClick={() =>
                onChange({ target: { name, value: option.value } })
              }
              isDisabled={isDisabled}
              colorScheme={
                value === option.value ? "brandSecondary" : undefined
              }
              size="sm"
              borderRadius="full"
              minW="2.5rem"
              h="2.5rem"
              variant="outline">
              <Text variant="text-xs" color="inherit">
                {intl.formatMessage(option.label)}
              </Text>
            </Button>
          ))}
        </HStack>
      </Box>
    );
  },
);
