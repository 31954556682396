import { isAnyAttributeSetInCampaign } from "../isAnyAttributeSet";

/**
 * @param {object} params
 * @param {import("../../../types/Campaign").Campaign} params.campaign
 * @returns {boolean}
 */
export function isAnyDateAttributeSetInCampaign({ campaign }) {
  return isAnyAttributeSetInCampaign({
    campaign,
    attributeNamesToCheck: [
      "checkin",
      "checkout",
      "min_checkin",
      "max_checkin",
      "min_checkout",
      "max_checkout",
      "min_duration",
      "max_duration",
    ],
  });
}
