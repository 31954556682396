import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import FormControlRHF from "@raiden/library-ui/components/ReactHookForm/FormControlRHF";
import { useConfiguration } from "@raiden/library-ui/hooks/useConfiguration";
import { memo, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DataHandler } from "@raiden/library-ui/components/DataHandler";
import { Icon } from "@raiden/library-ui/components/Icon";
import { getSearchPlaceLabel } from "../../../helpers/search/getSearchPlaceLabel";
import { PlacesSearch } from "../../Places/Search";
import { PlacesSearchMap } from "../../Places/Search/Map";
import { PlacesSearchResults } from "../../Places/Search/Results";

export const TabDestination = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/Campaign").Campaign} [campaign]
   * @property {import("react-hook-form").UseFormReturn<import("../Search/Handler").SearchFormValues>} form
   * @property {import("@raiden/library-ui/types/Region").Region} [region]
   * @property {import("@raiden/library-ui/types/Departement").Departement} [department]
   */
  /**
   * @param {Props} props
   */
  function TabDestination({ campaign, form, region, department }) {
    const intl = useIntl();

    const { configuration } = useConfiguration();

    const handleChangeTerm = useCallback(() => {
      const mapLocation = form.getValues("location.map");
      if (mapLocation !== "") {
        form.setValue("location", {
          map: "",
          place: "",
          region_code: "",
          department_code: "",
        });
      }
    }, [form]);

    const isInputPlaceDisabled =
      region !== undefined ||
      department !== undefined ||
      (campaign?.localization ?? undefined) !== undefined;

    return (
      <Box px="1rem">
        <FormControlRHF
          control={form.control}
          name="location"
          render={(field) => (
            <PlacesSearch
              {...field}
              campaign={campaign}
              onChangeTerm={handleChangeTerm}
              label={getSearchPlaceLabel({
                intl,
                configuration,
                value: field.value,
                campaign,
              })}>
              {({
                term,
                setTerm,
                swrResponse,
                getPlace,
                place,
                region_code,
                department_code,
                setRegionCode,
                setDepartmentCode,
                ref,
              }) => (
                <Box>
                  <InputGroup>
                    <InputLeftElement zIndex="initial">
                      <Icon
                        icon="ms_location_on"
                        size="1.5rem"
                        color="gray.500"
                      />
                    </InputLeftElement>

                    <Input
                      ref={ref}
                      value={term}
                      onChange={(e) => {
                        setTerm(e.target["value"]);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Escape") {
                          if (term !== "") {
                            e.stopPropagation();
                            setTerm("");
                          }
                        }
                        if (e.key === "Enter" && !swrResponse.isValidating) {
                          const firstResult = document.querySelector(
                            "#places-search-result-0",
                          );
                          firstResult?.["click"]?.();
                        }
                      }}
                      placeholder={intl.formatMessage({
                        defaultMessage: "Votre destination",
                      })}
                    />

                    <InputRightElement
                      zIndex="initial"
                      opacity={
                        Boolean(place) ||
                        Boolean(region_code) ||
                        Boolean(department_code) ||
                        Boolean(campaign?.localization) ||
                        field.value.map !== ""
                          ? isInputPlaceDisabled
                            ? 0.5
                            : 1
                          : 0
                      }
                      top="initial"
                      transform={`scale(${
                        Boolean(place) ||
                        Boolean(region_code) ||
                        Boolean(department_code) ||
                        Boolean(campaign?.localization) ||
                        field.value.map !== ""
                          ? 1
                          : 0
                      })`}
                      transition="all .25s ease">
                      <Icon icon="ms_check" size="1.5rem" color="green.500" />
                    </InputRightElement>
                  </InputGroup>

                  <Box mt="1rem">
                    {term.length >= 2 &&
                    region_code === "" &&
                    department_code === "" ? (
                      <DataHandler
                        swrResponse={swrResponse}
                        noResult={
                          <Text color="gray.500">
                            <FormattedMessage defaultMessage="Aucun résultat" />
                          </Text>
                        }>
                        {({ data: placesAutocomplete }) => (
                          <PlacesSearchResults
                            place={place}
                            term={term}
                            placesAutocomplete={placesAutocomplete}
                            getPlace={getPlace}
                          />
                        )}
                      </DataHandler>
                    ) : (
                      <Box>
                        <Text variant="h5">
                          <FormattedMessage defaultMessage="Pas d'idée précise ?" />
                        </Text>

                        <Text variant="text-xs" color="gray.500" mt="2px">
                          <FormattedMessage defaultMessage="Choisissez une région" />
                        </Text>

                        <Box mt="1rem">
                          <PlacesSearchMap
                            setRegionCode={setRegionCode}
                            setDepartmentCode={setDepartmentCode}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </PlacesSearch>
          )}
        />
      </Box>
    );
  },
);
