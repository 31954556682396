/**
 * @param {object} params
 * @param {string} params.code
 * @param {import("@raiden/library-ui/types/Configuration").Configuration} params.configuration
 * @returns {import("@raiden/library-ui/types/Configuration").ConfigurationDepartment | undefined}
 */
export function getDepartmentByCode({ code, configuration }) {
  const departmentsRecord = configuration?.departments ?? {};

  // search in the departments record if the property code matches the code parameter
  for (const key in departmentsRecord) {
    if (departmentsRecord[key].code === code) {
      return departmentsRecord[key];
    }
  }
}
