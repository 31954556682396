import { Box, Text } from "@chakra-ui/react";
import { useConfiguration } from "@raiden/library-ui/hooks/useConfiguration";
import { memo } from "react";
import { useWatch } from "react-hook-form";
import { useIntl } from "react-intl";
import { getSearchPlaceLabel } from "../../../helpers/search/getSearchPlaceLabel";
import { DatesRecap } from "./DatesRecap";

export const SearchRecap = memo(
  /**
   * @typedef {object} Props
   * @property {import("react-hook-form").UseFormReturn<import("../../../containers/Search/Search/Handler").SearchFormValues>} form
   * @property {import("@raiden/library-ui/types/Campaign").Campaign} [campaign]
   */
  /**
   * @param {Props} props
   */
  function SearchRecap({ form, campaign }) {
    const intl = useIntl();

    const adults = useWatch({ control: form.control, name: "adults" });
    const children = useWatch({ control: form.control, name: "children" });
    const location = useWatch({ control: form.control, name: "location" });

    const { configuration } = useConfiguration();

    const label =
      getSearchPlaceLabel({ intl, configuration, campaign, value: location }) ??
      intl.formatMessage({ defaultMessage: "N'importe où" });

    return (
      <Box>
        <Text variant="text-sm" fontWeight="bold">
          {label}
        </Text>

        <Text variant="text-sm" colorScheme="gray">
          {intl.formatMessage(
            { defaultMessage: "{when} • {guests}" },
            {
              when: (
                <DatesRecap
                  form={form}
                  fallback={intl.formatMessage({
                    defaultMessage: "N'importe quand",
                  })}
                />
              ),
              guests: intl.formatMessage(
                {
                  defaultMessage:
                    "{count, plural, =0 {Ajouter des voyageurs} one {# voyageur} other {# voyageurs}}",
                },
                { count: adults + children },
              ),
            },
          )}
        </Text>
      </Box>
    );
  },
);
