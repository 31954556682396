import { Input } from "@chakra-ui/react";
import dayjs from "dayjs";
import { forwardRef, useCallback, useEffect, useState } from "react";

export const CustomDateInput = forwardRef(
  /**
   * @typedef {object} Props
   * @property {string} minDate
   */
  /**
   * @param {import("@raiden/library-ui/components/ReactHookForm/FormControlRHF").FieldProps<string> & Props} props
   */
  function CustomDateInput(
    { name, value: _value, onChange, isDisabled, minDate },
    ref,
  ) {
    const [localValue, setLocalValue] = useState(_value);

    useEffect(() => {
      setLocalValue(_value);
    }, [_value]);

    const handleChange = useCallback(
      /** @type {import("react").ChangeEventHandler<HTMLInputElement>} */
      (e) => {
        const newValue = e.target.value;
        setLocalValue(newValue);

        if (newValue.match(/^\d{4}-\d{2}-\d{2}$/) || newValue === "") {
          if (dayjs(newValue).isBefore(dayjs(minDate))) {
            return;
          }
          onChange({
            target: {
              name,
              value: newValue,
            },
          });
        }
      },
      [minDate, name, onChange],
    );

    return (
      <Input
        ref={ref}
        type="date"
        value={localValue}
        onChange={handleChange}
        min={minDate}
        isDisabled={isDisabled}
        isInvalid={localValue !== _value}
      />
    );
  },
);
