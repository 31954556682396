import { defineMessage } from "react-intl";

/** @type {import("../types/Department").DepartmentFront[]} */
export const DEPARTMENTS_DOM_TOM = [
  {
    name: defineMessage({ defaultMessage: "Guadeloupe" }),
    code: "971",
    svg: (
      <svg
        version="1.1"
        id="Calque_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        xmlSpace="preserve"
        fill="none"
        stroke="#6DB6DE"
        strokeWidth=".5">
        <path
          className="st0"
          d="M7.888,9.05c0-0.8,0.667-1,1-1c0,0.667,0.5,2,2.5,2c2.5,0,1-1,1.5-1.5c0.5-0.5,3.5,0.5,4.5,0.5s-1.5-2.5-2-3.5
      s-1.5,0-3,0s-1-4-2.5-4.5c-1.5-0.5-1,1-2,2s2,1,2,2.5s-2,1-3.5,1c-1.5,0-1-1.5-3,0c-2,1.5-0.5,1.5,0,2.5c0.5,1-0.5,2,0,3.5
      s3,1.5,4.5,0S7.888,10.05,7.888,9.05z"
        />

        <path
          className="st0"
          d="M13.889,16.55c0.4-1.2,1.833-0.5,2.5,0c0,0.833-0.3,2.5-1.5,2.5C13.389,19.05,13.389,18.05,13.889,16.55z"
        />

        <path
          className="st1"
          d="M5.264,14.174c-0.253,0-0.501-0.026-0.739-0.083c-0.805-0.189-1.377-0.682-1.611-1.384
      c-0.303-0.908-0.135-1.661,0-2.265c0.121-0.541,0.182-0.862,0.027-1.17C2.839,9.068,2.655,8.902,2.477,8.742
      C2.201,8.495,1.857,8.186,1.879,7.699C1.9,7.227,2.25,6.778,3.089,6.149c1.31-0.983,1.854-0.912,2.513-0.4
      c0.215,0.168,0.386,0.3,0.787,0.3c0.31,0,0.641,0.021,0.971,0.041c0.726,0.046,1.625,0.102,1.911-0.168
      c0.051-0.047,0.118-0.137,0.118-0.373c0-0.409-0.456-0.668-1.099-0.99C7.769,4.298,7.276,4.052,7.195,3.55
      c-0.048-0.293,0.067-0.581,0.34-0.854c0.303-0.303,0.437-0.678,0.567-1.041c0.22-0.615,0.554-1.543,1.944-1.08
      c0.999,0.333,1.299,1.607,1.563,2.732c0.182,0.776,0.409,1.742,0.778,1.742c0.502,0,0.958-0.133,1.36-0.25
      c0.707-0.208,1.592-0.464,2.087,0.527c0.151,0.303,0.564,0.829,0.965,1.337c1.032,1.313,1.517,1.968,1.254,2.509
      c-0.056,0.113-0.23,0.377-0.666,0.377c-0.425,0-1.071-0.138-1.82-0.298c-0.667-0.142-1.857-0.399-2.28-0.338
      c0.009,0.042,0.021,0.089,0.03,0.126c0.061,0.243,0.162,0.649-0.114,1.004c-0.277,0.355-0.82,0.506-1.815,0.506
      c-1.75,0-2.614-0.946-2.895-1.838C8.432,8.785,8.389,8.893,8.389,9.049c0,0.204,0.126,0.47,0.26,0.751
      c0.346,0.728,0.868,1.828-0.406,3.102C7.437,13.709,6.311,14.174,5.264,14.174z M4.688,6.399c-0.178,0-0.445,0.134-1,0.55
      C2.938,7.513,2.881,7.725,2.877,7.747c0.02,0.028,0.169,0.163,0.268,0.251C3.369,8.2,3.648,8.451,3.836,8.826
      c0.32,0.641,0.185,1.248,0.054,1.835c-0.125,0.558-0.242,1.085-0.027,1.73c0.155,0.463,0.57,0.651,0.892,0.728
      c0.884,0.207,2.028-0.17,2.78-0.923c0.775-0.775,0.539-1.275,0.21-1.966C7.57,9.86,7.389,9.479,7.389,9.049
      c0-1.101,0.897-1.5,1.5-1.5c0.276,0,0.5,0.224,0.5,0.5c0,0.061,0.023,1.5,2,1.5c0.744,0,0.974-0.097,1.025-0.126
      c-0.001,0-0.002,0-0.002,0c-0.028,0-0.05-0.083-0.064-0.142c-0.062-0.251-0.18-0.719,0.188-1.086
      c0.481-0.481,1.547-0.283,3.241,0.078c0.405,0.086,0.818,0.175,1.147,0.227c-0.238-0.366-0.637-0.873-0.91-1.22
      c-0.45-0.573-0.875-1.114-1.072-1.508c-0.098-0.197-0.123-0.243-0.913-0.014c-0.443,0.129-0.995,0.291-1.64,0.291
      c-1.162,0-1.476-1.335-1.752-2.514C10.432,2.666,10.2,1.68,9.73,1.524C9.267,1.369,9.267,1.369,9.044,1.993
      C8.893,2.414,8.705,2.938,8.247,3.398c0.124,0.083,0.336,0.19,0.49,0.267c0.658,0.329,1.651,0.826,1.651,1.885
      c0,0.461-0.146,0.832-0.433,1.102c-0.602,0.566-1.647,0.5-2.658,0.438c-0.309-0.02-0.619-0.039-0.909-0.039
      c-0.745,0-1.14-0.308-1.401-0.511C4.888,6.461,4.809,6.399,4.688,6.399z"
        />

        <path
          className="st1"
          d="M14.889,19.549c-0.716,0-1.229-0.208-1.524-0.619c-0.479-0.665-0.244-1.657,0.05-2.539l0,0
      c0.202-0.606,0.601-0.845,0.899-0.937c0.969-0.306,2.149,0.526,2.375,0.695c0.126,0.095,0.2,0.243,0.2,0.4
      C16.889,17.995,16.263,19.549,14.889,19.549z M14.363,16.708c-0.358,1.073-0.3,1.483-0.188,1.639
      c0.121,0.168,0.454,0.203,0.713,0.203c0.707,0,0.939-1.111,0.989-1.746c-0.435-0.277-0.974-0.483-1.267-0.396
      C14.562,16.423,14.445,16.46,14.363,16.708z"
        />
      </svg>
    ),
  },
  {
    name: defineMessage({ defaultMessage: "Martinique" }),
    code: "972",
    svg: (
      <svg
        version="1.1"
        id="Calque_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        xmlSpace="preserve"
        fill="none"
        stroke="#6DB6DE"
        strokeWidth=".5">
        <path
          className="st0"
          d="M18.138,15.19c1.901,2.882-0.792,3.603-2.376,3.603V15.91c0-1.441-1.584,0-3.168,0H6.257
        c-1.584,0,0.792-2.162,3.168-2.162c1.901,0,0.792-1.921,0-2.882c-0.792-0.24-2.693-0.576-3.96,0c-1.584,0.72-1.584-0.72-1.584-2.882
        c0-2.162-1.584-1.441-2.376-2.882c-0.792-1.441,0-2.162,2.376-3.603c2.376-1.441,7.129,2.882,8.713,3.603
        c1.267,0.576,0.528,2.162,0,2.882c0.264,0.48,0.95,1.585,1.584,2.162C14.97,10.867,15.762,11.587,18.138,15.19z"
        />

        <path
          className="st1"
          d="M15.762,19.293c-0.276,0-0.5-0.224-0.5-0.5V15.91c0-0.063-0.004-0.108-0.009-0.14
        c-0.171,0.015-0.523,0.138-0.769,0.223c-0.535,0.186-1.201,0.417-1.891,0.417H6.257c-0.266,0-0.476-0.047-0.642-0.145
        c-0.236-0.137-0.371-0.369-0.371-0.636c0-0.983,2.163-2.381,4.182-2.381c0.281,0,0.489-0.055,0.529-0.14
        c0.113-0.238-0.19-1.006-0.819-1.806c-0.914-0.248-2.482-0.429-3.463,0.019c-0.64,0.292-1.145,0.312-1.543,0.054
        c-0.748-0.481-0.748-1.702-0.748-3.391c0-0.964-0.315-1.12-0.886-1.403C2.037,6.355,1.467,6.072,1.067,5.343
        C0.02,3.44,1.333,2.46,3.622,1.072C5.665-0.166,8.819,2,11.123,3.581c0.703,0.482,1.311,0.899,1.678,1.066
        c0.487,0.222,0.811,0.591,0.936,1.067c0.225,0.861-0.256,1.811-0.555,2.291c0.334,0.563,0.872,1.353,1.333,1.771
        c0.801,0.729,1.63,1.483,4.041,5.138l0,0c0.766,1.161,0.937,2.153,0.509,2.948C18.497,18.918,16.986,19.293,15.762,19.293z
         M15.299,14.769c0.203,0,0.39,0.047,0.559,0.167c0.269,0.191,0.404,0.519,0.404,0.974v2.358c0.822-0.08,1.644-0.361,1.922-0.879
        c0.3-0.557-0.086-1.354-0.462-1.924c-2.343-3.55-3.124-4.262-3.881-4.95c-0.846-0.77-1.651-2.229-1.686-2.291
        c-0.094-0.17-0.08-0.379,0.035-0.536c0.365-0.498,0.701-1.249,0.578-1.722c-0.03-0.115-0.103-0.283-0.382-0.41
        c-0.446-0.203-1.057-0.622-1.83-1.152C8.595,3.058,5.63,1.021,4.14,1.927C1.619,3.457,1.416,3.902,1.943,4.861
        c0.25,0.454,0.595,0.625,0.996,0.824C3.581,6.004,4.381,6.4,4.381,7.984c0,1.053,0,2.364,0.289,2.55
        c0.062,0.041,0.253,0.031,0.588-0.123c1.385-0.63,3.363-0.311,4.312-0.023c0.094,0.029,0.178,0.084,0.241,0.161
        c0.367,0.444,1.525,1.974,1.048,2.987c-0.154,0.325-0.527,0.713-1.434,0.713c-1.292,0-2.51,0.71-2.984,1.161h6.152
        c0.521,0,1.074-0.192,1.562-0.362C14.576,14.902,14.96,14.769,15.299,14.769z"
        />
      </svg>
    ),
  },
  {
    name: defineMessage({ defaultMessage: "Mayotte" }),
    code: "976",
    svg: (
      <svg
        version="1.1"
        id="Calque_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        fill="none"
        stroke="#6DB6DE"
        strokeWidth=".5">
        <path
          className="st0"
          d="M6,3.807C6,2.684,7.373,1.468,8.059,1c0.686,0.702,2.197,2.667,2.746,4.913c0.686,2.807,4.805,0,4.118,1.404
	c-0.686,1.404-1.373,0.702-1.373,3.509c0,2.807,1.373,0,0,4.913c-1.373,2.105,0,1.404-1.373,2.807
	c-1.373,1.404-3.432-0.702-4.118-2.807c-0.686-2.105,2.059-1.404,2.746-2.105s-0.686-1.404-2.746-2.807
	c-2.059-1.404,0-3.509,0-4.913C8.059,4.509,6,5.211,6,3.807z"
        />

        <path
          className="st1"
          d="M11.174,19.501c-0.151,0-0.306-0.019-0.463-0.055c-1.399-0.327-2.632-2.035-3.127-3.553
	c-0.195-0.601-0.175-1.102,0.062-1.488c0.384-0.63,1.178-0.758,1.879-0.871c0.339-0.055,0.805-0.13,0.923-0.251
	c-0.017-0.278-0.991-0.925-1.773-1.443c-0.28-0.186-0.58-0.385-0.897-0.601c-1.739-1.186-1.084-2.819-0.605-4.012
	C7.371,6.73,7.559,6.261,7.559,5.913c0-0.295-0.097-0.377-0.691-0.579C6.323,5.148,5.5,4.867,5.5,3.807
	c0-1.317,1.432-2.644,2.277-3.22c0.2-0.137,0.469-0.11,0.639,0.063c0.746,0.762,2.305,2.817,2.874,5.144
	c0.257,1.045,1.047,1.069,2.602,0.735c0.682-0.146,1.175-0.25,1.473,0.13c0.178,0.228,0.181,0.523,0.008,0.877
	c-0.265,0.54-0.535,0.817-0.752,1.039c-0.343,0.351-0.569,0.582-0.569,2.25c0,0.919,0.146,1.069,0.253,1.177
	c0.476,0.487,0.56,0.895-0.271,3.87c-0.014,0.049-0.035,0.096-0.062,0.139c-0.605,0.928-0.605,1.197-0.605,1.394
	c0,0.475-0.125,0.771-0.829,1.49C12.144,19.295,11.679,19.501,11.174,19.501z M7.995,1.671C7.313,2.219,6.5,3.103,6.5,3.807
	c0,0.296,0.097,0.377,0.691,0.58c0.545,0.186,1.368,0.466,1.368,1.526c0,0.541-0.223,1.097-0.459,1.686
	c-0.518,1.29-0.783,2.116,0.241,2.813c0.313,0.213,0.61,0.411,0.886,0.594c1.357,0.901,2.176,1.444,2.251,2.151
	c0.021,0.207-0.016,0.519-0.316,0.825c-0.349,0.357-0.898,0.445-1.479,0.539c-0.463,0.075-1.04,0.168-1.185,0.404
	c-0.096,0.157-0.034,0.444,0.036,0.657c0.453,1.39,1.531,2.686,2.403,2.89c0.342,0.08,0.621-0.008,0.882-0.276
	c0.544-0.556,0.544-0.654,0.544-0.791c0-0.394,0.057-0.835,0.726-1.875c0.568-2.046,0.617-2.634,0.557-2.766
	c-0.342-0.353-0.596-0.714-0.596-1.939c0-1.936,0.322-2.405,0.854-2.949c0.119-0.121,0.233-0.238,0.349-0.401
	c-0.053,0.011-0.104,0.022-0.152,0.032c-1.054,0.225-3.25,0.695-3.782-1.476C9.868,4.186,8.729,2.525,7.995,1.671z"
        />
      </svg>
    ),
  },
  {
    name: defineMessage({ defaultMessage: "Ile de la Réunion" }),
    code: "974",
    svg: (
      <svg
        version="1.1"
        id="Calque_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        fill="none"
        stroke="#6DB6DE"
        strokeWidth=".5">
        <path
          className="st0"
          d="M1.284,7.831c-0.581-1.794,2.322,0,2.903-1.196C3.8,5.838,3.722,3.766,6.509,1.853
	C8.25,0.059,7.67,2.451,9.992,3.646c2.322,1.196,1.742,0,4.644,0s0.58,2.989,0.58,4.783c0,1.794,2.903,1.794,3.483,2.989
	c0.58,1.196-1.741,1.794-1.741,3.587c0,1.794,1.741,1.196,1.741,2.989c0,1.794-4.064,0-6.966,0s-6.386-1.794-8.127-2.989
	c-1.742-1.196,0.581-2.391,0-4.185C3.025,9.027,1.865,9.625,1.284,7.831z"
        />

        <path
          className="st1"
          d="M17.533,19.292c-0.697,0-1.569-0.169-2.561-0.361c-1.104-0.214-2.245-0.436-3.239-0.436
	c-3.103,0-6.747-1.935-8.411-3.077C2,14.509,2.478,13.452,2.827,12.68c0.256-0.567,0.498-1.103,0.303-1.706
	C2.893,10.24,2.57,10.019,2.162,9.738c-0.451-0.31-1.011-0.694-1.354-1.753l0,0C0.75,7.802,0.569,7.246,0.905,6.834
	c0.36-0.441,1.002-0.371,1.625-0.303c0.388,0.043,0.853,0.094,1.082-0.005C3.285,5.422,3.493,3.316,6.225,1.44
	C6.579,1.062,7.05,0.663,7.569,0.712c0.504,0.05,0.774,0.469,1.06,0.913c0.325,0.505,0.729,1.133,1.592,1.577
	c1.176,0.605,1.415,0.523,1.992,0.33c0.482-0.163,1.143-0.385,2.423-0.385c0.819,0,1.372,0.214,1.689,0.656
	c0.532,0.74,0.168,1.829-0.185,2.881c-0.209,0.623-0.425,1.267-0.425,1.747c0,0.668,0.61,0.989,1.616,1.438
	c0.734,0.328,1.493,0.667,1.817,1.332c0.442,0.914-0.216,1.592-0.745,2.137c-0.465,0.479-0.946,0.976-0.946,1.669
	c0,0.634,0.246,0.803,0.654,1.083c0.458,0.315,1.087,0.747,1.087,1.906c0,0.503-0.228,0.796-0.418,0.953
	C18.478,19.198,18.054,19.292,17.533,19.292z M1.712,7.484C1.718,7.526,1.731,7.589,1.76,7.677l0,0
	C1.998,8.412,2.32,8.633,2.728,8.914c0.451,0.31,1.011,0.694,1.354,1.753c0.314,0.97-0.05,1.777-0.343,2.424
	c-0.375,0.829-0.456,1.086,0.15,1.502c1.569,1.077,4.987,2.901,7.845,2.901c1.091,0,2.333,0.241,3.43,0.454
	c1.175,0.228,2.642,0.513,2.981,0.228c0.017-0.014,0.055-0.045,0.055-0.182c0-0.633-0.246-0.802-0.653-1.082
	c-0.459-0.315-1.088-0.747-1.088-1.907c0-1.1,0.682-1.801,1.229-2.365c0.542-0.559,0.681-0.758,0.562-1.004
	c-0.164-0.337-0.754-0.601-1.324-0.855c-0.984-0.439-2.209-0.986-2.209-2.352c0-0.643,0.242-1.365,0.477-2.064
	c0.247-0.737,0.555-1.655,0.321-1.979c-0.111-0.155-0.423-0.24-0.878-0.24c-1.116,0-1.664,0.185-2.104,0.333
	c-0.779,0.263-1.29,0.374-2.77-0.389C8.662,3.524,8.136,2.707,7.788,2.166c-0.115-0.178-0.271-0.42-0.345-0.465
	c0.012,0.013-0.151,0.062-0.576,0.5C4.317,3.964,4.3,5.724,4.636,6.418c0.067,0.138,0.067,0.299,0,0.437
	C4.215,7.721,3.178,7.607,2.42,7.525C2.196,7.501,1.872,7.465,1.712,7.484z"
        />
      </svg>
    ),
  },
  {
    name: defineMessage({ defaultMessage: "Guyane" }),
    code: "973",
    svg: (
      <svg
        version="1.1"
        id="Calque_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        xmlSpace="preserve"
        fill="none"
        stroke="#6DB6DE"
        strokeWidth=".5">
        <path
          className="st0"
          d="M6.522,2.759C6.998,2.527,6.403,1.832,6.76,1.6c0.238-0.116,0.715,0.116,1.31,0.347
	C8.665,2.18,8.665,2.527,9.261,2.759c0.595,0.232,1.072,0,1.786,0.116C11.762,2.99,11.643,3.338,12,3.801
	c0.357,0.463,1.191,0.695,1.548,0.927c0.357,0.232,0.238,0.463,0.596,0.695c0.357,0.232,0.953,0.463,1.072,0.579
	c0.119,0.116,0.715,0.695,1.191,0.695c0.476,0,0.595,0.927,0.834,1.274s0.357,1.506,0.238,1.737
	c-0.119,0.232-0.476,0.463-0.834,0.811c-0.357,0.348-0.476,0.811-1.072,1.506c-0.595,0.695-0.119,0.579-0.476,1.042
	c-0.357,0.463-0.476,0.116-0.715,0.463c-0.238,0.347,0,0.695-0.238,0.926c-0.238,0.232-0.476,0.927-0.596,1.274
	c-0.119,0.347-0.476,1.158-1.191,1.737c-0.715,0.579-0.715,0.695-1.31,0.695c-0.595,0-0.476-0.695-0.715-0.695
	c-0.238,0-0.595-0.232-0.834,0.232c-0.238,0.463-1.191,0.116-1.429-0.232c-0.238-0.348-0.238-0.579-0.715-0.232
	c-0.476,0.347-0.357,0.463-1.191,0.926c-0.834,0.463-1.072,0.232-1.31,0l0,0c-0.238-0.232-0.595-0.347-1.191-0.695
	c-0.595-0.348,0.357-0.348,0.953-0.579c0.595-0.232,0.238-0.463,0.238-0.811s0.595-0.463,0.715-0.695
	c0.119-0.232,0-1.506-0.119-1.853c-0.119-0.348,0.715-0.811,0.953-1.158c0.238-0.348,0.119-0.579-0.238-1.042
	c-0.238-0.116,0-0.232-0.357-0.811C5.45,9.94,5.331,10.171,4.973,9.708C4.616,9.245,4.616,8.666,4.497,7.971
	c-0.119-0.695,0-0.463,0.119-0.579c0.119-0.116-0.119-1.158-0.357-1.506C4.021,5.538,4.854,4.149,5.092,3.801
	c0.238-0.347,0.595-0.463,0.715-0.695C5.926,2.875,6.045,2.99,6.522,2.759z"
        />

        <path
          className="st1"
          d="M5.383,18.93c-0.358,0-0.625-0.164-0.877-0.409c-0.094-0.091-0.259-0.176-0.488-0.293
	c-0.171-0.088-0.371-0.19-0.606-0.327c-0.329-0.192-0.473-0.43-0.43-0.706c0.067-0.434,0.503-0.532,0.926-0.628
	c0.165-0.037,0.348-0.077,0.505-0.135c-0.034-0.117-0.058-0.227-0.058-0.354c0-0.491,0.408-0.732,0.652-0.877
	c0.032-0.02,0.075-0.044,0.108-0.065c0.026-0.312-0.053-1.199-0.137-1.443c-0.186-0.543,0.296-0.957,0.683-1.289
	c0.125-0.108,0.282-0.242,0.331-0.313c0.028-0.083-0.009-0.172-0.167-0.383C5.581,11.534,5.552,11.3,5.537,11.18
	c-0.011-0.087-0.023-0.185-0.156-0.399c-0.131-0.212-0.184-0.244-0.281-0.302c-0.16-0.095-0.32-0.202-0.523-0.466
	c-0.361-0.468-0.433-0.988-0.51-1.539C4.048,8.34,4.029,8.2,4.004,8.055C3.951,7.746,3.877,7.313,4.133,7.082
	C4.09,6.803,3.96,6.335,3.846,6.169C3.404,5.524,4.282,4.101,4.68,3.519c0.17-0.249,0.378-0.405,0.53-0.52
	c0.059-0.044,0.139-0.104,0.159-0.131c0.166-0.323,0.42-0.389,0.588-0.433c0.062-0.016,0.143-0.037,0.252-0.083
	c-0.006-0.04-0.015-0.085-0.022-0.121C6.138,1.985,6.037,1.473,6.488,1.181C6.94,0.956,7.399,1.141,7.98,1.374l0.271,0.108
	c0.393,0.153,0.598,0.353,0.763,0.513C9.15,2.127,9.239,2.214,9.442,2.293c0.235,0.091,0.451,0.08,0.75,0.062
	c0.267-0.014,0.571-0.032,0.934,0.026c0.693,0.112,0.903,0.433,1.089,0.809c0.046,0.091,0.095,0.195,0.18,0.306
	c0.165,0.214,0.568,0.396,0.892,0.543c0.211,0.095,0.4,0.184,0.533,0.27c0.29,0.188,0.398,0.399,0.47,0.538
	c0.049,0.094,0.059,0.113,0.125,0.156c0.164,0.106,0.383,0.211,0.573,0.304c0.302,0.146,0.464,0.228,0.576,0.336
	c0.451,0.438,0.748,0.554,0.842,0.554c0.67,0,0.924,0.681,1.093,1.132c0.05,0.133,0.111,0.297,0.153,0.359
	c0.303,0.44,0.506,1.791,0.271,2.249c-0.121,0.235-0.332,0.416-0.577,0.625c-0.11,0.095-0.231,0.198-0.353,0.316
	c-0.124,0.121-0.218,0.275-0.348,0.489c-0.158,0.263-0.355,0.589-0.692,0.982c-0.184,0.214-0.209,0.292-0.21,0.295
	c0.011,0.235-0.029,0.441-0.25,0.729c-0.26,0.336-0.493,0.426-0.708,0.461c-0.02,0.045-0.022,0.097-0.022,0.203
	c0,0.193,0,0.516-0.271,0.778c-0.07,0.068-0.208,0.311-0.472,1.078c-0.096,0.281-0.478,1.258-1.349,1.964l-0.324,0.271
	c-0.47,0.397-0.665,0.535-1.301,0.535c-0.653,0-0.886-0.481-0.982-0.728c-0.006-0.001-0.013-0.003-0.019-0.004
	c-0.044-0.01-0.116-0.028-0.132-0.021c-0.119,0.309-0.412,0.487-0.773,0.511c-0.527,0.024-1.21-0.273-1.482-0.669
	c-0.019-0.027-0.036-0.053-0.052-0.079c-0.12,0.093-0.19,0.165-0.259,0.236c-0.208,0.214-0.397,0.39-0.94,0.691
	C5.989,18.833,5.659,18.93,5.383,18.93z M4.624,17.416c0.226,0.118,0.413,0.228,0.579,0.389c0.149,0.144,0.21,0.205,0.719-0.078
	c0.456-0.254,0.573-0.374,0.708-0.514c0.109-0.112,0.221-0.228,0.431-0.381c0.219-0.159,0.481-0.314,0.783-0.254
	c0.306,0.062,0.447,0.297,0.551,0.468l0.088,0.141c0.095,0.126,0.498,0.271,0.627,0.231c0.3-0.639,0.91-0.513,1.138-0.466
	c0.525,0.017,0.675,0.422,0.731,0.575c0.018,0.048,0.048,0.129,0.067,0.151c0.302-0.016,0.302-0.016,0.654-0.314l0.342-0.284
	c0.68-0.551,0.979-1.354,1.031-1.51c0.198-0.578,0.416-1.164,0.708-1.459c-0.032,0.015-0.02-0.039-0.02-0.074
	c0-0.194,0-0.488,0.207-0.789c0.209-0.306,0.44-0.373,0.64-0.399c-0.002,0.008,0.036-0.016,0.092-0.087
	c0.036-0.357,0.106-0.611,0.492-1.062c0.284-0.331,0.45-0.606,0.597-0.849c0.148-0.246,0.289-0.479,0.506-0.689
	c0.138-0.134,0.275-0.252,0.4-0.359c0.129-0.109,0.305-0.26,0.338-0.322c0.032-0.171-0.078-1.035-0.206-1.226
	c-0.111-0.163-0.187-0.363-0.266-0.575c-0.059-0.156-0.167-0.445-0.24-0.507c-0.36,0.028-0.884-0.256-1.454-0.811
	c-0.041-0.026-0.171-0.084-0.316-0.155c-0.227-0.11-0.485-0.237-0.681-0.364c-0.29-0.188-0.398-0.399-0.47-0.538
	c-0.049-0.094-0.059-0.113-0.125-0.156c-0.1-0.065-0.244-0.127-0.401-0.198c-0.425-0.192-0.953-0.432-1.271-0.843
	c-0.134-0.172-0.215-0.331-0.285-0.474c-0.109-0.221-0.111-0.225-0.353-0.264c-0.257-0.043-0.481-0.029-0.717-0.015
	c-0.343,0.02-0.732,0.043-1.17-0.128C8.688,3.072,8.482,2.872,8.317,2.712C8.182,2.58,8.092,2.493,7.888,2.414L7.607,2.302
	C7.476,2.25,7.32,2.187,7.186,2.14c0.051,0.296,0.086,0.81-0.446,1.069l0,0C6.5,3.325,6.335,3.369,6.214,3.401
	C6.104,3.577,5.95,3.693,5.812,3.797C5.694,3.886,5.583,3.97,5.504,4.084C5.168,4.575,4.746,5.406,4.718,5.682
	c0.208,0.387,0.678,1.644,0.249,2.066c0.006,0.04,0.014,0.085,0.023,0.138c0.027,0.156,0.048,0.306,0.068,0.45
	C5.121,8.788,5.17,9.145,5.369,9.403C5.473,9.537,5.525,9.568,5.612,9.62c0.197,0.117,0.38,0.246,0.62,0.636
	c0.206,0.333,0.263,0.57,0.286,0.721c0.015,0.015,0.029,0.031,0.042,0.048c0.306,0.397,0.726,0.942,0.254,1.629
	c-0.123,0.18-0.308,0.339-0.504,0.507c-0.108,0.094-0.278,0.239-0.354,0.332c0.109,0.466,0.267,1.71,0.056,2.119
	c-0.114,0.223-0.318,0.343-0.498,0.449c-0.041,0.024-0.1,0.06-0.144,0.089c0.005,0.017,0.011,0.033,0.016,0.048
	c0.068,0.203,0.275,0.82-0.589,1.157C4.742,17.377,4.684,17.397,4.624,17.416z M4.541,16.373L4.541,16.373L4.541,16.373z
	 M4.671,5.603C4.671,5.603,4.671,5.604,4.671,5.603C4.671,5.604,4.671,5.603,4.671,5.603z M6.303,2.309L6.303,2.309L6.303,2.309
	L6.303,2.309z"
        />
      </svg>
    ),
  },
  // {
  //   name: defineMessage({ defaultMessage: "Nouvelle calédonie" }),
  //   // code: "988",
  //   svg: (
  //     <svg
  //       version="1.1"
  //       id="Calque_1"
  //       xmlns="http://www.w3.org/2000/svg"
  //       x="0px"
  //       y="0px"
  //       viewBox="0 0 20 20"
  //       xmlSpace="preserve"
  //       fill="none"
  //       stroke="#6DB6DE"
  //       strokeWidth=".5">
  //       <path
  //         className="st0"
  //         d="M7.024,10.496C5.15,9.04,2.227,4.309,1,2.125c1.673,1.092,5.689,3.931,8.366,6.551
  //     c3.346,3.276,4.35,1.82,6.023,6.551C17.062,19.959,9.366,12.316,7.024,10.496z"
  //       />

  //       <path
  //         className="st0"
  //         d="M14.093,7.587c-0.737,0.715-1.536-1.093-1.843-2.087h1.843C14.401,5.898,14.831,6.871,14.093,7.587z"
  //       />

  //       <path
  //         className="st0"
  //         d="M9.436,2.921c-0.676-1.105,0.676-0.737,1.351-0.737c0.113,0.491,0.338,1.621,0.338,2.211
  //     c0,0.737-1.351,1.105-2.027,1.105c-0.676,0,0.338-0.368,1.014-0.737C10.788,4.395,10.112,4.026,9.436,2.921z"
  //       />

  //       <path
  //         className="st0"
  //         d="M15.626,9.538c0-0.733,0.826-0.713,1.24-0.611c0.207,0.305,0.682,0.978,0.93,1.222
  //     c0.31,0.306-0.31,0.611-1.24,0.916C15.626,11.371,15.626,10.454,15.626,9.538z"
  //       />

  //       <path
  //         className="st1"
  //         d="M12.483,10.465c-0.711-0.446-1.597-1-2.768-2.146c-2.673-2.616-6.691-5.47-8.442-6.612
  //     C1.082,1.582,0.828,1.603,0.659,1.76C0.491,1.918,0.452,2.169,0.564,2.37c0.152,0.271,3.758,6.661,6.152,8.521
  //     c0.486,0.378,1.214,1.018,2.057,1.758c3.529,3.101,5.422,4.597,6.479,4.597c0.188,0,0.351-0.048,0.49-0.143
  //     c0.601-0.406,0.379-1.305,0.118-2.043C14.734,11.875,13.839,11.314,12.483,10.465z M9.434,11.898
  //     c-0.855-0.752-1.594-1.4-2.104-1.797C6.037,9.097,4.068,6.246,2.571,3.807c1.942,1.379,4.545,3.367,6.445,5.227
  //     c1.248,1.222,2.184,1.808,2.937,2.279c1.26,0.789,1.954,1.225,2.965,4.081c0.166,0.468,0.203,0.722,0.207,0.849
  //     C14.182,16.069,11,13.273,9.434,11.898z"
  //       />

  //       <path
  //         className="st1"
  //         d="M13.507,8.22c0.079,0.02,0.157,0.03,0.234,0.03c0.252,0,0.494-0.104,0.701-0.305v0
  //     c0.852-0.828,0.621-2.006,0.047-2.751C14.395,5.072,14.249,5,14.094,5h-1.843c-0.159,0-0.308,0.075-0.402,0.203
  //     c-0.094,0.128-0.122,0.292-0.075,0.444C11.987,6.342,12.586,7.984,13.507,8.22z M13.819,6c0.151,0.284,0.345,0.821-0.074,1.229
  //     l0.011,0.023C13.597,7.208,13.261,6.733,12.96,6H13.819z"
  //       />

  //       <path
  //         className="st1"
  //         d="M9.098,6c0.881,0,2.527-0.471,2.527-1.605c0-0.789-0.336-2.261-0.35-2.323
  //     c-0.053-0.227-0.255-0.388-0.488-0.388c-0.117,0-0.257-0.013-0.402-0.026C9.812,1.607,9.163,1.55,8.864,2.03
  //     c-0.281,0.454,0.04,0.979,0.145,1.151c0.208,0.34,0.416,0.615,0.588,0.842C9.678,4.13,9.772,4.253,9.833,4.346
  //     C9.669,4.433,9.487,4.521,9.317,4.602C8.672,4.912,8.283,5.1,8.389,5.562C8.455,5.853,8.694,6,9.098,6z M10.298,2.654
  //     c0.027,0.002,0.055,0.005,0.082,0.007c0.062,0.299,0.125,0.646,0.173,0.968c-0.05-0.068-0.103-0.139-0.158-0.211
  //     C10.239,3.214,10.05,2.967,9.863,2.66C9.855,2.648,9.848,2.636,9.842,2.626C9.992,2.628,10.176,2.644,10.298,2.654z"
  //       />

  //       <path
  //         className="st1"
  //         d="M19.344,16.655l-0.281-0.268c-0.093-0.089-0.217-0.138-0.345-0.138c-0.367,0-1.344,0-1.344,0.768
  //     c0,0.311,0,0.955,0.454,1.242c0.082,0.052,0.221,0.115,0.404,0.115c0.121,0,0.261-0.027,0.419-0.103
  //     c0.669-0.317,0.848-0.938,0.848-1.255C19.499,16.88,19.442,16.75,19.344,16.655z"
  //       />

  //       <path
  //         className="st1"
  //         d="M18.357,10.466c0.057-0.24-0.021-0.485-0.212-0.674c-0.192-0.19-0.622-0.784-0.865-1.145
  //     c-0.069-0.103-0.175-0.176-0.295-0.206c-0.213-0.054-0.944-0.193-1.451,0.206c-0.187,0.146-0.408,0.419-0.408,0.891
  //     c0,0.752,0,1.53,0.52,1.906c0.167,0.121,0.361,0.182,0.581,0.182c0.15,0,0.312-0.028,0.485-0.086
  //     C17.826,11.174,18.257,10.893,18.357,10.466z M16.399,10.59c-0.106,0.035-0.16,0.036-0.167,0.042
  //     c-0.106-0.142-0.106-0.76-0.106-1.095c0-0.038,0.004-0.087,0.025-0.104c0.046-0.036,0.154-0.06,0.288-0.06
  //     c0.042,0,0.086,0.002,0.132,0.007c0.152,0.22,0.424,0.602,0.659,0.885C17.083,10.341,16.83,10.449,16.399,10.59z"
  //       />
  //     </svg>
  //   ),
  // },
  // {
  //   name: defineMessage({ defaultMessage: "Saint-Pierre-Et-Miquelon" }),
  //   // code: "975",
  //   svg: (
  //     <svg
  //       version="1.1"
  //       id="Calque_1"
  //       xmlns="http://www.w3.org/2000/svg"
  //       x="0px"
  //       y="0px"
  //       viewBox="0 0 20 20"
  //       xmlSpace="preserve"
  //       fill="none"
  //       stroke="#6DB6DE"
  //       strokeWidth=".5">
  //       <path
  //         className="st0"
  //         d="M6.293,2.644C6.08,1.986,7.269,1.274,7.89,1C7.535,1.548,7.145,2.698,8.422,2.918
  // c1.065,0.274,0,0.548,2.129,1.644c1.597,0.274,0.266,1.096,0.266,2.466c0,1.37-0.799,1.096-1.331,1.096
  // c-0.532,0,0.266-0.548,0-0.822C9.221,7.027,8.955,7.027,8.422,7.575C7.996,8.013,8.6,8.488,8.955,8.671v3.562
  // c0.266,0,0.958,0.055,1.597,0.274c0.799,0.274,0.799,1.096,0.799,1.918c0,0.822-2.395,2.466-3.726,2.74
  // c-1.331,0.274-0.266-1.096-0.266-1.37c0-0.274-0.799-0.274-1.331-0.548c-0.532-0.274,1.863-2.466,2.395-3.014
  // c0.426-0.438,0.177-2.557,0-3.562C7.979,8.123,6.985,6.863,6.559,6.205c-0.426-0.658,0-1.735,0.266-2.192
  // C6.736,3.831,6.506,3.301,6.293,2.644z"
  //       />

  //       <path
  //         className="st0"
  //         d="M11.35,18.655c0,0.777,1.678,0,2.516,0l-0.839-0.518l0.839-0.777c0.28-0.259,0.28-0.777-0.28-1.035
  // C13.028,16.066,11.35,17.878,11.35,18.655z"
  //       />

  //       <path
  //         className="st1"
  //         d="M11.85,14.424c0-0.844,0-2-1.137-2.391c-0.44-0.151-0.899-0.231-1.259-0.271V8.671
  // c0-0.016-0.001-0.032-0.002-0.048c0.073,0.004,0.153,0.004,0.236,0.01c0.328,0.021,0.821,0.055,1.198-0.298
  // c0.291-0.272,0.433-0.7,0.433-1.307c0-0.402,0.152-0.769,0.287-1.092c0.175-0.417,0.354-0.85,0.124-1.268
  // c-0.207-0.376-0.639-0.513-1.015-0.584C9.713,3.559,9.576,3.292,9.486,3.114C9.314,2.779,9.122,2.581,8.507,2.425
  // c-0.165-0.028-0.375-0.091-0.436-0.23c-0.083-0.188,0.02-0.585,0.238-0.922C8.43,1.086,8.414,0.844,8.27,0.676
  // C8.128,0.507,7.89,0.453,7.688,0.542C7.308,0.71,5.431,1.607,5.817,2.798c0.16,0.495,0.331,0.922,0.451,1.199
  // c-0.32,0.657-0.617,1.727-0.129,2.48C6.549,7.11,7.46,8.273,7.951,8.884c0.251,1.49,0.257,2.774,0.112,3
  // c-0.082,0.085-0.209,0.209-0.363,0.359c-1.819,1.777-2.322,2.443-2.243,2.973c0.031,0.206,0.155,0.379,0.34,0.475
  // c0.274,0.142,0.587,0.219,0.838,0.281c0.032,0.007,0.066,0.016,0.1,0.024c-0.204,0.422-0.452,0.977-0.123,1.399
  // c0.19,0.244,0.47,0.305,0.709,0.305c0.171,0,0.321-0.03,0.402-0.047C9.048,17.381,11.85,15.679,11.85,14.424z M8.063,7.227
  // C8.036,7.255,8.011,7.284,7.987,7.313C7.591,6.8,7.197,6.27,6.978,5.933C6.727,5.544,6.99,4.724,7.257,4.265
  // c0.083-0.144,0.09-0.32,0.018-0.47C7.188,3.615,6.969,3.109,6.768,2.49c0,0,0,0,0,0c-0.02-0.062,0.081-0.207,0.277-0.381
  // c0.008,0.167,0.042,0.333,0.11,0.487C7.288,2.898,7.6,3.284,8.297,3.402c0.209,0.054,0.257,0.091,0.298,0.167
  // c0.192,0.376,0.484,0.797,1.728,1.437c0.045,0.023,0.094,0.04,0.145,0.048c0.226,0.04,0.298,0.085,0.368,0.08
  // c-0.017,0.092-0.094,0.277-0.152,0.417c-0.153,0.369-0.364,0.875-0.364,1.477c0,0.456-0.104,0.566-0.115,0.577
  // c-0.031,0.028-0.104,0.039-0.19,0.041c0.057-0.213,0.051-0.468-0.167-0.692c-0.213-0.22-0.452-0.331-0.708-0.331
  // C8.709,6.621,8.354,6.927,8.063,7.227z M7.527,16.673c0.045-0.106,0.1-0.221,0.135-0.293c0.114-0.233,0.196-0.402,0.196-0.586
  // c0-0.551-0.567-0.691-0.981-0.794c-0.099-0.024-0.203-0.05-0.304-0.079c0.375-0.543,1.438-1.583,1.827-1.963
  // c0.118-0.114,0.22-0.215,0.3-0.295c0.075,0.044,0.162,0.069,0.255,0.069c0.199,0,0.845,0.044,1.435,0.246
  // c0.417,0.144,0.461,0.551,0.461,1.44C10.797,14.898,8.811,16.407,7.527,16.673z"
  //       />

  //       <path
  //         className="st1"
  //         d="M13.85,18.057l0.356-0.329c0.263-0.244,0.386-0.6,0.33-0.951c-0.061-0.387-0.331-0.717-0.74-0.906
  // c-0.302-0.138-0.67-0.073-1.092,0.196c-0.738,0.472-1.854,1.744-1.854,2.588c0,0.265,0.105,0.494,0.296,0.646
  // c0.185,0.146,0.41,0.2,0.669,0.2c0.334,0,0.721-0.089,1.14-0.187c0.339-0.078,0.689-0.159,0.912-0.159
  // c0.224,0,0.42-0.148,0.481-0.363c0.061-0.216-0.028-0.445-0.219-0.562L13.85,18.057z M12.688,17.77
  // c-0.114,0.106-0.173,0.26-0.158,0.416c0.007,0.066,0.026,0.13,0.057,0.187c-0.232,0.053-0.518,0.114-0.704,0.127
  // c0.19-0.545,1.183-1.652,1.494-1.721c0.118,0.055,0.167,0.115,0.15,0.215L12.688,17.77z"
  //       />
  //     </svg>
  //   ),
  // },
  // {
  //   name: defineMessage({ defaultMessage: "Polynésie française" }),
  //   // code: "689",
  //   svg: (
  //     <svg
  //       version="1.1"
  //       id="Calque_1"
  //       xmlns="http://www.w3.org/2000/svg"
  //       x="0px"
  //       y="0px"
  //       viewBox="0 0 20 20"
  //       xmlSpace="preserve"
  //       fill="none"
  //       stroke="#6DB6DE"
  //       strokeWidth=".5">
  //       <path
  //         className="st0"
  //         d="M12.936,8.718c0.32,0.629,1.601,0.315,2.241,0.315c0-0.315-0.128-1.007-0.64-1.258
  //       c-0.64-0.315,0.64-1.887,0.64-2.516c0-0.629,0.96-0.944,1.281-1.573c0.32-0.629-1.281-2.516-1.921-2.831
  //       c-0.64-0.315-1.601,0.629-1.601,0.944s-0.32,2.516-0.64,2.831c-0.32,0.315-1.281,0.944-1.601,1.573
  //       c-0.32,0.629,0.64,0.629,1.601,0.629S12.616,8.089,12.936,8.718z"
  //       />

  //       <path
  //         className="st0"
  //         d="M11.335,16.582c-0.095,0-0.178,0.006-0.261,0.012l-0.532-0.922c0.049-0.011,0.108-0.023,0.153-0.034
  //       c-0.107-0.21-0.768-0.944-2.561-2.202c-1.281-1.573-2.882-1.573-4.162-1.573c-1.281,0,0.32,2.831,1.601,3.774
  //       c0.961,0.708,3.485,0.365,4.853,0.06l0.426,0.926c-0.594,0.108-0.858,0.455-1.117,1.216c-0.32,0.944,0.64,0.314,2.561,1.258
  //       c0.768,0.503,1.814-0.839,2.241-1.573C13.79,17.211,12.104,16.582,11.335,16.582z"
  //       />

  //       <path
  //         className="st1"
  //         d="M14.731,17.065c-0.095-0.04-2.342-0.983-3.396-0.983c-0.194,0-0.364,0.017-0.522,0.042c0,0,0.001,0,0.001,0
  //       c0.148-0.036,0.272-0.139,0.335-0.277s0.061-0.299-0.009-0.435c-0.18-0.354-0.975-1.154-2.665-2.346
  //       c-1.423-1.702-3.203-1.702-4.505-1.702c-0.446,0-0.788,0.231-0.916,0.62c-0.348,1.058,1.117,3.244,2.22,4.057
  //       c0.528,0.389,1.365,0.516,2.233,0.516c1.173,0,2.389-0.23,2.977-0.358c-0.659,0.213-0.966,0.717-1.224,1.481
  //       c-0.076,0.225-0.157,0.558,0.019,0.851c0.198,0.331,0.56,0.392,0.979,0.461c0.432,0.071,1.022,0.17,1.794,0.545
  //       c0.187,0.114,0.394,0.173,0.609,0.173c0.098,0,0.197-0.012,0.298-0.036c1.002-0.236,1.851-1.62,2.011-1.895
  //       c0.072-0.125,0.087-0.274,0.042-0.41C14.966,17.231,14.864,17.122,14.731,17.065z M5.868,15.236
  //       c-1.004-0.74-1.886-2.391-1.873-2.872c1.233,0,2.627,0.009,3.75,1.388c0.029,0.036,0.063,0.067,0.101,0.094
  //       c0.918,0.645,1.522,1.142,1.896,1.484C8.154,15.62,6.448,15.663,5.868,15.236z M12.729,18.7c-0.087,0.018-0.126,0.002-0.16-0.02
  //       c-0.017-0.012-0.034-0.021-0.053-0.031c-0.915-0.448-1.625-0.566-2.095-0.645c-0.065-0.011-0.139-0.023-0.203-0.035
  //       c0.261-0.763,0.424-0.888,1.116-0.888c0.506,0,1.605,0.358,2.448,0.681C13.444,18.22,13.034,18.628,12.729,18.7z"
  //       />

  //       <path
  //         className="st1"
  //         d="M16.914,3.012c-0.306-0.918-1.513-2.29-2.156-2.606c-0.404-0.199-0.9-0.138-1.395,0.171
  //       c-0.32,0.2-0.927,0.712-0.927,1.221c-0.001,0.348-0.31,2.199-0.491,2.474c-0.081,0.08-0.209,0.181-0.355,0.299
  //       c-0.448,0.361-1.062,0.855-1.34,1.403c-0.207,0.406-0.093,0.702-0.007,0.842c0.315,0.515,1.12,0.514,2.089,0.483
  //       c0.037,0.075,0.023,0.352,0.016,0.517C12.33,8.18,12.311,8.59,12.491,8.945c0.276,0.544,0.862,0.666,1.449,0.666
  //       c0.262,0,0.523-0.024,0.758-0.045c0.18-0.017,0.345-0.033,0.479-0.033c0.276,0,0.5-0.224,0.5-0.5c0-0.442-0.175-1.261-0.813-1.648
  //       c0.04-0.21,0.253-0.638,0.374-0.882c0.235-0.475,0.439-0.884,0.439-1.244c0-0.122,0.252-0.33,0.454-0.497
  //       c0.274-0.228,0.586-0.485,0.771-0.849C17.028,3.668,17.031,3.365,16.914,3.012z M15.495,3.991c-0.364,0.301-0.817,0.675-0.817,1.267
  //       c-0.004,0.132-0.211,0.55-0.335,0.799c-0.31,0.624-0.603,1.212-0.438,1.701c0.07,0.207,0.213,0.368,0.411,0.465
  //       c0.122,0.06,0.211,0.195,0.271,0.348c-0.322,0.03-1.114,0.1-1.204-0.079c-0.001,0-0.001,0-0.001-0.001
  //       c-0.061-0.119-0.047-0.4-0.036-0.626c0.021-0.422,0.043-0.901-0.278-1.238c-0.188-0.196-0.447-0.296-0.771-0.296
  //       c-0.314,0-0.792,0-1.069-0.043c0.23-0.325,0.68-0.687,0.99-0.937c0.178-0.143,0.331-0.268,0.43-0.364
  //       c0.526-0.517,0.79-3.059,0.777-3.104c0.045-0.109,0.34-0.432,0.659-0.554c0.102-0.038,0.188-0.046,0.233-0.025
  //       c0.563,0.276,1.727,1.792,1.696,2.155C15.921,3.638,15.704,3.817,15.495,3.991z"
  //       />
  //     </svg>
  //   ),
  // },
];
