import { useRouter } from "next/router";
import { useMemo } from "react";

export function getBoolean(value) {
  if (typeof value === "string") {
    if (value === "1") {
      return true;
    }
    if (value === "0") {
      return false;
    }
  }
  if (typeof value === "boolean") {
    return value;
  }
  return undefined;
}

/**
 * @param {string} key
 * @returns {boolean | undefined}
 */
export function useRouterBoolean(key) {
  const router = useRouter();

  const value = useMemo(() => {
    return getBoolean(router.query[key]);
  }, [key, router.query]);

  return value;
}
