import { Box, HStack, Stack, Text } from "@chakra-ui/react";
import { memo } from "react";
import { useIntl } from "react-intl";
import { REGIONS } from "../../../constants/regions";
import { DEPARTMENTS_DOM_TOM } from "../../../constants/departments";

export const PlacesSearchMap = memo(
  /**
   * @typedef {object} Props
   * @property {import(".").PlacesSearchSetRegionCode} setRegionCode
   * @property {import(".").PlacesSearchSetDepartmentCode} setDepartmentCode
   */
  /**
   * @param {Props} props
   */
  function PlacesSearchMap({ setRegionCode, setDepartmentCode }) {
    const intl = useIntl();

    return (
      <Stack
        direction={{ base: "column-reverse", md: "row" }}
        spacing="1rem"
        alignItems={{ base: "flex-start", lg: "flex-start" }}>
        <Stack spacing="0.75rem" flexShrink={0}>
          {DEPARTMENTS_DOM_TOM.map((domTom, index) => (
            <HStack
              as="a"
              onClick={() => {
                setDepartmentCode(domTom.code);
              }}
              tabIndex={0}
              spacing="0.75rem"
              cursor="pointer"
              sx={{
                "&:hover path": {
                  fill: "#6DB6DE",
                },
              }}
              role="group"
              key={index}>
              <Box w="20px">{domTom.svg}</Box>

              <Text
                variant="text-xs"
                color="gray.500"
                _groupHover={{ textDecoration: "underline" }}>
                {intl.formatMessage(domTom.name)}
              </Text>
            </HStack>
          ))}
        </Stack>

        <Box w="261px" alignSelf="center">
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 261 244"
            xmlSpace="preserve">
            {REGIONS.map((department, index) => (
              <Box
                key={index}
                as="a"
                onClick={() => {
                  setRegionCode(department.code);
                }}
                tabIndex={0}
                cursor="pointer"
                sx={{
                  "& path:hover": {
                    fill: "#6DB6DE",
                  },
                }}>
                {department.svg}
              </Box>
            ))}
          </svg>
        </Box>
      </Stack>
    );
  },
);
