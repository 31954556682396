import { Stack } from "@chakra-ui/react";
import { memo } from "react";
import { useIntl } from "react-intl";
import { GuestInput } from "../GuestInput";
import FormControlRHF from "../../ReactHookForm/FormControlRHF";
import FormObserver from "../../ReactHookForm/FormObserver";

export const GuestsInputBody = memo(
  /**
   * @typedef {object} Props
   * @property {boolean} [isAdultsDisabled]
   * @property {boolean} [isChildrenDisabled]
   * @property {boolean} [isBabiesDisabled]
   * @property {boolean} [isPetsDisabled]
   * @property {number} [maxCapacity]
   */
  /**
   * @param {Props} props
   */
  function GuestsInputBody({
    isAdultsDisabled = false,
    isChildrenDisabled = false,
    isBabiesDisabled = false,
    isPetsDisabled = false,
    maxCapacity,
  }) {
    const intl = useIntl();

    return (
      <Stack spacing="1rem">
        <FormObserver name="children">
          {(children) => (
            <FormControlRHF
              name="adults"
              isDisabled={isAdultsDisabled}
              render={(field) => (
                <GuestInput
                  title={intl.formatMessage({ defaultMessage: "Adultes" })}
                  description={intl.formatMessage({
                    defaultMessage: "(+ de 18 ans)",
                  })}
                  icon="sf_adult"
                  min={1}
                  max={
                    maxCapacity !== undefined
                      ? maxCapacity - children
                      : undefined
                  }
                  {...field}
                />
              )}
            />
          )}
        </FormObserver>

        <FormObserver name="adults">
          {(adults) => (
            <FormControlRHF
              name="children"
              isDisabled={isChildrenDisabled}
              render={(field) => (
                <GuestInput
                  title={intl.formatMessage({ defaultMessage: "Enfants" })}
                  description={intl.formatMessage({
                    defaultMessage: "(2 à 18 ans)",
                  })}
                  icon="sf_child"
                  max={
                    maxCapacity !== undefined ? maxCapacity - adults : undefined
                  }
                  {...field}
                />
              )}
            />
          )}
        </FormObserver>

        <FormControlRHF
          name="babies"
          isDisabled={isBabiesDisabled}
          render={(field) => (
            <GuestInput
              title={intl.formatMessage({ defaultMessage: "Bébés" })}
              description={intl.formatMessage({
                defaultMessage: "(- de 2 ans)",
              })}
              icon="sf_baby"
              max={20}
              {...field}
            />
          )}
        />

        <FormControlRHF
          name="pets"
          isDisabled={isPetsDisabled}
          render={(field) => (
            <GuestInput
              title={intl.formatMessage({ defaultMessage: "Animaux" })}
              icon="sf_pet"
              {...field}
            />
          )}
        />
      </Stack>
    );
  },
);
