import { Button, Text, Wrap } from "@chakra-ui/react";
import { forwardRef } from "react";
import { useIntl } from "react-intl";
import { SEARCH_DATES_PRECISE_MODES_LIST } from "../../../constants/Search";

export const SearchDatesPreciseModePicker = forwardRef(
  /**
   * @param {import("@raiden/library-ui/components/ReactHookForm/FormControlRHF").FieldProps<import("../../../types/Search").SearchDatesPreciseMode>} props
   */
  function SearchModePicker({ name, value, onChange }, ref) {
    const intl = useIntl();

    return (
      <Wrap>
        {SEARCH_DATES_PRECISE_MODES_LIST.map((mode, index) => (
          <Button
            ref={index === 0 ? ref : undefined}
            variant="outline"
            onClick={() => {
              onChange({
                target: { name, value: mode.value },
              });
            }}
            borderRadius="full"
            colorScheme={value === mode.value ? "brandSecondary" : undefined}
            key={mode.value}>
            <Text variant="text-xs" color="inherit">
              {intl.formatMessage(mode.label)}
            </Text>
          </Button>
        ))}
      </Wrap>
    );
  },
);
