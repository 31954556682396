import { defineMessage } from "react-intl";

/** @type {import("../types/Region").RegionFront[]} */
export const REGIONS = [
  {
    name: defineMessage({ defaultMessage: "Corse" }),
    code: "94",
    svg: (
      <path
        id="corse"
        fill="transparent"
        stroke="#6DB6DE"
        d="M252.655,208.852c5.471,2.041-1.238-9.142,3.801-7.552c0.969,10.823,1.126,6.097,2.675,13.183
c0.331,1.514,1.999,6.629-0.235,10.84c-1.642,3.097-0.963,5.983-1.097,9.056c-0.207,4.744-3.126,9.775-3.878,8.495
c-1.736-2.956-6.344-2.802-6.992-4.364c-0.692-1.668,0.868-1.336,2.076-2.451c1.208-1.115-3.318-0.891-3.953-2.241
c-0.494-1.05,1.433-2.665,0.704-3.426c-0.96-1.001-3.401,0.459-3.191-1.22c0.188-1.502,0.892-1.408,2.018-2.018
c1.334-0.723-2.824-2.328-3.144-3.707c-0.408-1.757,2.439-1.629,1.642-2.44c-2.722-2.769-2.697-0.737-0.491-3.928
c1.087-1.572-0.21-2.627,1.899-3.956c3.426-2.159,6.126-5.86,8.165-4.27L252.655,208.852z"
      />
    ),
  },
  {
    name: defineMessage({ defaultMessage: "Centre-Val de Loire" }),
    code: "24",
    svg: (
      <path
        id="centre-val-de-loire"
        fill="transparent"
        stroke="#6DB6DE"
        d="M135.15,106.693c-2.589-2.601-0.01-6.1-2.826-8.165
c-1.975-1.448,0.623-5.577-0.844-7.696c-1.347-3.929,7.236-8.822,1.793-15.861c-3.975,2.2-9.828,4.023-9.322-2.159
c-2.127-2.634-5.615,0.452-8.041-0.939c-0.447-3.817-2.604-3.313-5.256-6.57c-1.022-1.256,0.5-2.963-2.483-5.309
c-0.134-0.105-0.231-0.197-0.297-0.278c-0.724-0.759-0.955-1.839-0.946-2.964c-2.171,1.369-2.411,2.852-7.536,3.671
c-4.176,0.328-3.988,2.526-2.018,4.74c2.768,3.148-2.632,4.599-2.112,7.414c3.473,18.771-16.436,15.308-12.582,27.921
c2.774,1.151,3.338,6.314,6.2,5.35c0.637-0.629,4.516-0.624,4.786,0.751c0.337,1.713,0.743,6.175,3.942,7.696
c5.725,2.722,3.645,6.24,6.007,7.414c2.459,1.223,9.194-2.743,13.605-1.868c3.363,0.667,7.384-1.518,8.892-2.361
c1.271-0.71-1.849-4.406,4.064-6.752c2.503-0.993,9.141,4.411,10.324,1.877C142.04,109.306,137.552,109.106,135.15,106.693z"
      />
    ),
  },
  {
    name: defineMessage({ defaultMessage: "Bourgogne-Franche-Comté" }),
    code: "27",
    svg: (
      <path
        id="bourgogne-franche-compte"
        fill="transparent"
        stroke="#6DB6DE"
        d="M205.933,89.349c-0.852-1.335-2.225-3.075-3.234-4.368
c-3.635-4.661-9.209-3.046-10.674-5.036c-1.465-1.99-7.515-1.054-9.479,1.22c-1.783,2.065-2.534,10.324-14.354,6.844
c-1.035-0.305-1.991-0.713-1.99-1.425c0.013-11.425-8.893-5.259-14.722-5.606c-4.369-0.26-4.595-5.03-6.013-4.708
c-6.473,1.475,2.304-5.582-7.371-6.728c-4.707,0.972-2.784,2.579-4.823,5.429c5.443,7.039-3.14,11.932-1.793,15.861
c1.467,2.119-1.131,6.248,0.844,7.696c2.816,2.065,0.237,5.565,2.826,8.165c1.963,1.972,5.318,2.469,5.636,4.387h0.003
c5.455,1.528,1.172,4.317,5.906,5.373c10.512,2.346-0.005,5.441,4.599,8.634c4.837,4.449,11.71-7.576,13.421,0.375
c4.547,1.643-0.462-8.844,4.599-8.447c9.573,0.751,9.878,5.537,14.383,4.59c1.093-0.23,5.702-0.005,5.702-3.511
c0-4.066,3.775-8.441,4.998-9.221c2.471-1.576-2.177-4.249,2.393-5.49c4.386-1.191-0.645-2.05,4.892-5.804
c6.894-4.674-3.17-6.494,4.252-8.232L205.933,89.349z"
      />
    ),
  },
  {
    name: defineMessage({ defaultMessage: "Grand Est" }),
    code: "44",
    svg: (
      <path
        id="grand-est"
        fill="transparent"
        stroke="#6DB6DE"
        d="M217.608,58.041c2.501-3.016,3.993-5.028,3.25-7.584c-2.327-3.728-9.11,0.659-11.399-3.026
c-1.923-3.388-5.574,1.829-7.641-1.382c-3.95-3.479-3.127,5.929-6.585-0.239c-1.401-0.882-1.009-2.604-1.282-3.966
c-4.413-5.682-8.097-0.936-9.985-0.892c-1.889,0.044-3.512-4.146-6.3-2.581c-1.418,2.517-2.913,0.562-3.642-1.789
c-0.514-1.141-2.207-0.737-2.954-1.068c-1.345-1.779-2.722-2.782-5.012-2.681c-2.445-0.471-3.008-14.99-5.499-6.466
c-2.699,4.112-7.551-0.949-8.106,7.583c-0.343,0.965,0.987,1.824-0.27,2.383c-5.115,4.641,3.682,7.392-7.391,8.072
c-1.256,2.06,0.86,5.111-1.852,6.726c0.484,3.415-1.408,4.891-3.85,3.209c3.555,4.559,5.669,14.092-0.111,15.036
c-0.321,0.052-0.609,0.108-0.883,0.164c9.675,1.147,0.898,8.203,7.372,6.728c1.417-0.323,1.644,4.448,6.013,4.708
c5.829,0.347,14.735-5.819,14.722,5.606c0,0.712,0.954,1.12,1.99,1.425c11.82,3.48,12.571-4.779,14.354-6.844
c1.964-2.274,8.014-3.21,9.479-1.22c1.465,1.99,7.039,0.375,10.674,5.036c2.144,2.749,3.169,5.871,6.9,5.874
c4.447-1.929,2.714-11.243,3.281-16.126c1.433-6.24,1.373-11.424,4.728-16.688L217.608,58.041z"
      />
    ),
  },
  {
    name: defineMessage({ defaultMessage: "Ile-de-France" }),
    code: "11",
    svg: (
      <path
        id="ile-de-france"
        fill="transparent"
        stroke="#6DB6DE"
        d="M107.621,52.711c3.51-7.028,8.206-5.766,14.577-3.456c4.203,1.523,7.117,6.153,11.997,3.056
c5.151-3.269,13.061,15.715,4.783,17.066c-5.838,0.953-3.548,2.578-5.706,5.594c-3.975,2.2-9.828,4.023-9.321-2.159
c-2.127-2.634-5.615,0.452-8.041-0.939c-0.447-3.817-2.604-3.313-5.256-6.57c-1.022-1.256,0.5-2.963-2.483-5.309
C106.073,58.344,107.153,54.531,107.621,52.711z"
      />
    ),
  },
  {
    name: defineMessage({ defaultMessage: "Hauts-de-France" }),
    code: "32",
    svg: (
      <path
        id="hauts-de-france"
        fill="transparent"
        stroke="#6DB6DE"
        d="M155.069,28.665c-1.314-2.534,0.636-10.476-3.963-10.1
c-6.394,0.522-4.736-1.799-7.132-4.054c-1.438-1.353-4.506,1.426-5.444-1.108c-0.783-1.354-0.967-6.737-2.909-5.537
c-6.382,3.942-7.191-3.462-9.385-6.476c-4.04-1.654-10.453,1.6-14.673,1.98c-3.131,3.593-0.556,9.335-2.07,13.788
c-0.914,2.688,1.088,5.055-1.097,6.945c-6.186,5.35,3.307,4.005,3.199,7.978c0.584,2.705-0.107,4.71-0.188,7.79
c-0.077,2.962,1.023,5.683,0.52,8.136c2.828-1.26,6.208-0.223,10.273,1.25c4.203,1.523,7.117,6.153,11.997,3.056
c1.554-0.986,3.358,0.06,4.894,2.03c2.442,1.682,4.334,0.206,3.85-3.209c2.712-1.615,0.596-4.667,1.852-6.726
c11.073-0.68,2.276-3.432,7.391-8.072c1.257-0.559-0.073-1.418,0.27-2.383c0.24-3.696,1.287-4.84,2.616-5.285L155.069,28.665z"
      />
    ),
  },
  {
    name: defineMessage({ defaultMessage: "Normandie" }),
    code: "28",
    svg: (
      <path
        id="normandie"
        fill="transparent"
        stroke="#6DB6DE"
        d="M86.676,67.885c2.222,1.689,4.225,6.039,8.884,6.845c-0.067-0.687-0.156-1.395-0.296-2.152
c-0.521-2.815,4.879-4.266,2.112-7.414c-1.97-2.213-2.159-4.411,2.018-4.74c5.125-0.818,5.366-2.301,7.536-3.671
c0.012-1.492,0.44-3.057,0.693-4.042c1.271-2.545,2.699-3.996,4.305-4.71c0.501-2.452-0.598-5.171-0.521-8.132
c0.08-3.08,0.772-5.085,0.188-7.79c0.079-2.898-4.946-2.969-5.272-4.87c-5.144,0.117-4.232,2.515-6.741,2.806
c-4.758,0.551-5.051,0.94-7.696,2.628c-2.963,1.891-2.628-0.094-5.537,2.065c-3.024,2.244-3.064,4.096-2.972,5.431
c0.059,0.853,2.776,1.541,2.198,1.937c-1.483,1.016-4.857,3.59-11.966,1.267c-4.336-1.417-6.617-2.534-8.024-1.267
c-3.601,3.241-3.025-1.893-3.375-6.261c-0.356-4.438-0.401,2.121-9.084-2.326c-1.522-0.78-2.026-0.155-0.724,3.319
c0.563,1.502-0.483,3.166,0.724,4.635c2.447,2.981,4.634,4.468,3.437,7.425c-1.098,2.712,0.755,3.761-0.058,6.172
c-0.564,1.673,0.092,4.53,1.619,6.394c1.096,1.339,1.956,3.665,3.543,3.965c4.987,2.319,10.914,0.986,15.767-0.985
c6.94,3.833,0.749,4.754,9.245,3.473L86.676,67.885z"
      />
    ),
  },
  {
    name: defineMessage({ defaultMessage: "Bretagne" }),
    code: "53",
    svg: (
      <path
        id="bretagne"
        fill="transparent"
        stroke="#6DB6DE"
        d="M62.3,65.662c-0.213-0.085-0.426-0.167-0.636-0.264c-1.358-0.257-2.184-1.995-3.081-3.331
c-1.238-0.32-2.726-0.742-5.389-0.141c-1.46,0.267-1.908-3.443-3.168-3.168c-1.502,0.328-1.401,5.526-5.913,2.393
c-2.376-1.65-3.571-1.27-6.124,1.083c-4.268,3.932-3.254-6.839-8.728-7.7c-6.57-1.032-6.245-0.703-7.391,3.308
c-0.563,1.971-1.079-0.774-3.097-0.352c-1.7,0.356-1.939,0.853-2.675,0.422c-2.886-1.689-2.955,0.33-5.62,0.099
c-5.22-0.451-4.65,1.53-7.965,1.872c-1.201,0.124-2.284,5.607-0.915,5.631c4.012,0.07,4.712-0.858,5.842-0.282
c5.642,2.878-3.392,0.796-3.66,2.675c-0.211,1.478,4.929,1.246,5.35,3.097c0.334,1.473-7.593,0.349-7.602,1.76
c-0.015,2.253,3.215,1.134,4.716,2.675c1.587,1.628-0.375,3.168,1.267,4.716c2.324,0.523,2.879-1.742,4.927-1.83
c0.958-0.64,2.853,0.769,3.027,1.971c1.898,1.349,2.18,0.419,4.223,1.197c1.485,0.565,2.162,3.231,5.119,1.401
c1.5-0.928-0.473,1.837,3.047,3.949c2.596,1.558,4.64-1.502,6.892-0.493c2.227,0.998-3.386,2.096-2.773,2.616
c2.569,2.182,4.793-0.636,5.714,0.014c2.041,1.443,1.183,1.91,1.183,1.91l0.236,0.222c1.843-0.389,5.808,0.199,5.753-3.346
c-0.094-6.1,5.336-0.297,6.593-2.278c1.671-2.635,4.715-2.985,6.458-3.011c3.968-0.057,0.561-3.586,2.956-3.871
c4.407-0.525-1.027-11.107,1.434-12.946V65.662z"
      />
    ),
  },
  {
    name: defineMessage({ defaultMessage: "Pays de la Loire" }),
    code: "52",
    svg: (
      <path
        id="pays-de-la-loire"
        fill="transparent"
        stroke="#6DB6DE"
        d="M82.681,100.499c-3.699-12.105,14.475-9.416,12.878-25.769
c-4.659-0.806-6.662-5.156-8.884-6.845c-8.495,1.282-2.304,0.36-9.245-3.473c-4.649,1.888-10.281,3.182-15.131,1.249
c-2.461,1.839,2.973,12.421-1.434,12.946c-2.395,0.285,1.011,3.814-2.956,3.871c-1.743,0.025-4.787,0.375-6.458,3.011
c-1.256,1.981-6.687-3.823-6.593,2.278c0.069,4.464-6.251,2.367-6.658,3.933c-1.783,6.851,5.546,2.826,6.28,6.194
c0.388,1.781-1.334,0.564,0,2.816c0.84,1.1,3.316,1.565,0,3.59c-2.581,1.576-0.453,5.304,3.715,8.517
c2.746,2.117,3.757,6.112,6.194,5.702c1.695-0.286,2.374,4.186,4.716,3.427c3.566-1.155,12.602-0.882,11.328-2.254
c-2.483-2.675-1.557-6.413-0.912-9.151c0.466-1.978-4.502-4.336-3.432-5.894c1.776-2.584,10.965-1.051,16.59-4.148H82.681z"
      />
    ),
  },
  {
    name: defineMessage({ defaultMessage: "Nouvelle-Aquitaine" }),
    code: "75",
    svg: (
      <path
        id="nouvelle-aquitaine"
        fill="transparent"
        stroke="#6DB6DE"
        d="M117.941,119.939c-0.242-0.02-0.483-0.05-0.72-0.097
c-4.411-0.875-11.146,3.091-13.605,1.868c-2.362-1.174-0.282-4.693-6.007-7.414c-3.199-1.521-3.605-5.983-3.942-7.696
c-0.27-1.375-4.15-1.38-4.786-0.751c-2.862,0.965-3.426-4.198-6.2-5.35c-5.625,3.097-14.814,1.564-16.59,4.148
c-1.07,1.558,3.897,3.916,3.432,5.894c-0.645,2.738-1.571,6.476,0.912,9.151c1.268,1.366-7.68,1.102-11.278,2.239
c0.244,2.75,2.76,5.603,1.499,10.947c-0.369,1.564-1.587,4.667,0.493,6.054c6.124,4.083,7.032,12.085,6.969,15.063
c0.401,1.904-1.553-6.097-2.745-7.532c-4.035-4.853-4.471-6.077-5.49-3.731c-1.66,3.818-2.079,17.239-3.168,21.047
c3.519-4.998,3.801,0.493,0.845,1.689c1.047,5.176-3.592,12.708-4.435,20.906c-0.434,4.226-4.548,10.989-7.25,10.629
c1.337,1.901,2.182,1.126,6.969,2.956c1.938,0.741-3.099,4.054-0.282,5.631c1.76,0.985-2.463-2.602,11.474,3.168
c2.5,1.035,5.276,5.276,7.476,4.083c1.823-0.989,0.408-6.265,3.906-8.291c3.421-1.981,3.295-8.532,3.259-10.151
c-0.074-3.419-6.617-1.549-5.35-4.716c1.438-3.595-0.823-1.751,2.534-5.631c4.571-5.284,20.785,1.34,18.865-8.165
c0.902-2.008,4.605-1.233,3.731-4.575c-1.842-6.048,7.908-8.572,6.828-14.148c-0.725-2.575,10.751,2.632,11.464,1.116
c6.828-14.5,8.119-6.461,9.301-12.238c0.634-3.097-1.508-7.717,0-11.826c4.092-11.144-3.081-10.93-8.106-14.277H117.941z"
      />
    ),
  },
  {
    name: defineMessage({ defaultMessage: "Occitanie" }),
    code: "76",
    svg: (
      <path
        id="occitanie"
        fill="transparent"
        stroke="#6DB6DE"
        d="M163.565,174.056c-2.111-1.372-5.573,1.05-7.039,0.141c-3.681-2.284-5.677-8.75-8.024-13.444
c-0.774-1.549-6.179-0.191-5.983,1.056c-0.486-3.096,1.408-4.153-4.083-3.238c-3.149,0.525-1.267,7.039-4.364,5.772
c-4.147-1.697-3.254-7.579-6.336-4.693c-3.436,3.217-3.659,7.596-6.616,6.171c-2.087-1.006-3.308-5.277-4.227-7.846
c-0.05,0.104-0.097,0.198-0.147,0.304c-0.714,1.516-12.189-3.691-11.464-1.116c1.08,5.577-8.67,8.1-6.828,14.148
c0.875,3.342-2.828,2.567-3.731,4.575c1.92,9.505-14.293,2.881-18.865,8.165c-3.357,3.88-1.096,2.036-2.534,5.631
c-1.267,3.168,5.275,1.297,5.35,4.716c0.035,1.619,0.161,8.17-3.259,10.151c-3.498,2.026-2.083,7.302-3.906,8.291
c-0.102,0.055-0.206,0.087-0.31,0.12c0.701-0.135,1.658-0.419,2.97-0.894c2.814-1.019,1.763,2.936,4.083,3.097
c6.07,0.421,2.505-0.139,10.342,0.258c5.561,0.282,0.533-3.158,3.665-3.848c1.451,0.769,7.026,3.828,12.811,5.068
c14.98,3.21,7.779,10.776,12.952,7.813c7.743-4.435,7.884,2.041,9.855,0.211c5.2-4.828,11.621-0.219,10.981-2.112
c-1.549-4.575-4.213-11.24-1.337-17.034c3.344-4.195,10.922-8.006,15.273-10.984c3.443-2.356,8.864-3.039,10.49-5.839
c0.993-1.711-0.015-3.035,2.044-3.873c3.831-1.559,1.756-8.481-1.763-10.768V174.056z"
      />
    ),
  },
  {
    name: defineMessage({ defaultMessage: "Provence-Alpes-Côte d'Azur" }),
    code: "93",
    svg: (
      <path
        id="provence-alpes-cote-azur"
        fill="transparent"
        stroke="#6DB6DE"
        d="M221.566,174.338c-2.886-2.956-10.418,6.958-15.633-8.658
c5.215-4.505,2.367-8.002,1.766-8.024c-7.532-0.282,0.022-5.507-6.161-5.115c-4.482,0.284-5.068-0.779-7.565,0.469
c-1.689,0.845,2.243,4.206,0.211,6.194c-0.795,0.778-3.864-0.076-4.786,0.845c-1.028,1.026-2.408,3.178-3.519,4.294
c-0.651,0.653-2.58,0.115-3.238,0.774c-0.447,0.448,1.5,3.287,1.056,3.731c-0.631,0.63-4.364-0.569-4.857,0.141
c-1.76,2.534,8.94,7.391,6.124,7.743c-6.147,0.768-9.887-3.269-10.558-3.027c-2.39,0.863-3.34,1.358-4.083,1.629
c-0.978,0.055-6.623-2.381-8.297-1.704c-0.002,0-0.003,0.002-0.005,0.003c0.555,0.012,1.083,0.124,1.544,0.424
c3.519,2.287,5.593,9.209,1.763,10.768c-2.06,0.838-1.051,2.162-2.044,3.873c-1.625,2.801-7.047,3.483-10.49,5.839
c8.024-3.097,11.725,4.789,17.177,1.833c5.347-2.9,3.349,1.914,6.373,1.251c3.739-0.619,1.658,2.493,3.663,3.362
c3.42-0.232,6.382,2.259,9.321,3.594c4.775-1.739,12.721-1.248,14.742-5.288c-1.904-3.994,7.221-7.061,8.868-10.272
c0.419-3.24,6.16-2.601,6.853-5.49c-1.494-3.669,5.208-5.672,1.774-9.19L221.566,174.338z"
      />
    ),
  },
  {
    name: defineMessage({ defaultMessage: "Auvergne-Rhône-Alpes" }),
    code: "84",
    svg: (
      <path
        id="auvergne-rhone-alpes"
        fill="transparent"
        stroke="#6DB6DE"
        d="M156.526,174.197c1.146,0.711,3.509-0.607,5.495-0.564
c0.002,0,0.003-0.002,0.005-0.003c1.674-0.677,7.319,1.759,8.297,1.704c0.743-0.272,1.693-0.767,4.083-1.629
c0.671-0.242,4.412,3.795,10.558,3.027c2.816-0.352-7.884-5.209-6.124-7.743c0.493-0.709,4.226,0.489,4.857-0.141
c0.444-0.443-1.503-3.283-1.056-3.731c0.658-0.66,2.587-0.121,3.238-0.774c1.111-1.116,2.492-3.268,3.519-4.294
c0.922-0.92,3.991-0.067,4.786-0.845c2.032-1.989-1.901-5.35-0.211-6.194c2.497-1.248,3.083-0.185,7.564-0.469
c-0.371-1.383-0.414-2.095,1.798-2.065c4.102,0.056,6.446-4.636,6.687-6.969c0.177-1.715-3.449-1.689-3.301-6.131
c0.046-1.388-3.56,0.485-3.527-3.512c0.024-2.865,3.847-1.491,3.66-4.364c-0.062-0.957-1.912-1.766-2.534-3.391
c-0.559-1.462-2.816-1.114-1.619-5.732c0.481-1.857-1.126-3.97-2.956-3.758c-9.161,1.057-7.743,4.223-7.039,5.328
c0.644,1.011,0.683,3.973-4.505,3.26c-2.451-1.854,4.927-4.223,1.197-7.109c0,3.506-4.609,3.281-5.702,3.511
c-4.505,0.947-4.81-3.839-14.383-4.59c-5.061-0.397-0.052,10.089-4.599,8.447c-1.711-7.951-8.584,4.073-13.421-0.375
c-4.603-3.193,5.913-6.288-4.599-8.634c-4.734-1.057-0.451-3.846-5.906-5.373h-0.003c-0.02-0.123-0.054-0.24-0.099-0.352
c0.2,0.501,0.174,1.104-0.188,1.879c-1.183,2.534-7.821-2.87-10.324-1.877c-5.913,2.346-2.793,6.042-4.064,6.752
c-1.402,0.783-4.974,2.723-8.172,2.458c5.025,3.347,12.197,3.133,8.106,14.277c-1.508,4.108,0.634,8.728,0,11.826
c-1.173,5.734-2.457-2.134-9.154,11.933c0.919,2.57,2.14,6.84,4.227,7.846c2.956,1.425,3.18-2.954,6.616-6.171
c3.082-2.886,2.189,2.996,6.336,4.693c3.097,1.267,1.215-5.247,4.364-5.772c5.49-0.915,3.597,0.142,4.083,3.238
c-0.195-1.247,5.209-2.604,5.983-1.056c2.347,4.695,4.343,11.161,8.024,13.444L156.526,174.197z"
      />
    ),
  },
  {
    name: defineMessage({ defaultMessage: "Région d'outre-mer" }),
    code: "01",
    svg: null,
  },
];
