/**
 * @template [T=number]
 * @function initNumber
 * @param {any} value
 * @param {object} [options]
 * @param {T} options.defaultValue
 * @returns {number | T}
 */
export function initNumber(value, options) {
  if (isNaN(value)) {
    return options?.defaultValue ?? 0;
  }
  const numberValue = Number(value);
  return numberValue;
}
