import { Box, Button, HStack, Text } from "@chakra-ui/react";
import { forwardRef, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import {
  SEARCH_RANKING_VALUE_0,
  SEARCH_RANKING_VALUE_1,
  SEARCH_RANKING_VALUE_2,
  SEARCH_RANKING_VALUE_3,
  SEARCH_RANKING_VALUE_4,
  SEARCH_RANKING_VALUE_5,
} from "../../../constants/Search";
import { Icon } from "@raiden/library-ui/components/Icon";

/** @type {{value: import("../../../types/Search").SearchRanking, component: import("react").FunctionComponent}[]} */
const OPTIONS = [
  {
    value: SEARCH_RANKING_VALUE_0,
    component: () => (
      <HStack justifyContent="center" spacing="6px">
        <Text color="inherit">
          <FormattedMessage defaultMessage="Indifférent" />
        </Text>
      </HStack>
    ),
  },
  {
    value: SEARCH_RANKING_VALUE_1,
    component: () => (
      <HStack justifyContent="center" spacing="6px">
        <Text>
          <FormattedMessage defaultMessage="1 clé" />
        </Text>

        <HStack
          spacing="0.25rem"
          backgroundColor="brandSecondary.300"
          borderRadius="full"
          color="#fff"
          py="4px"
          px="6px">
          <Icon icon="sf_key" />
        </HStack>
      </HStack>
    ),
  },
  {
    value: SEARCH_RANKING_VALUE_2,
    component: () => (
      <HStack justifyContent="center" spacing="6px">
        <Text>
          <FormattedMessage defaultMessage="2 clés" />
        </Text>

        <HStack
          spacing="0.25rem"
          backgroundColor="brandSecondary.300"
          borderRadius="full"
          color="#fff"
          py="4px"
          px="6px">
          <Icon icon="sf_key" />

          <Icon icon="sf_key" />
        </HStack>
      </HStack>
    ),
  },
  {
    value: SEARCH_RANKING_VALUE_3,
    component: () => (
      <HStack justifyContent="center" spacing="6px">
        <Text>
          <FormattedMessage defaultMessage="3 clés" />
        </Text>

        <HStack
          spacing="0.25rem"
          backgroundColor="brandSecondary.300"
          borderRadius="full"
          color="#fff"
          py="4px"
          px="6px">
          <Icon icon="sf_key" />

          <Icon icon="sf_key" />

          <Icon icon="sf_key" />
        </HStack>
      </HStack>
    ),
  },
  {
    value: SEARCH_RANKING_VALUE_4,
    component: () => (
      <HStack justifyContent="center" spacing="6px">
        <Text>
          <FormattedMessage defaultMessage="4 clés" />
        </Text>

        <HStack
          spacing="0.25rem"
          backgroundColor="brandSecondary.300"
          borderRadius="full"
          color="#fff"
          py="4px"
          px="6px">
          <Icon icon="sf_key" />

          <Icon icon="sf_key" />

          <Icon icon="sf_key" />

          <Icon icon="sf_key" />
        </HStack>
      </HStack>
    ),
  },
  {
    value: SEARCH_RANKING_VALUE_5,
    component: () => (
      <HStack justifyContent="center" spacing="6px">
        <Text>
          <FormattedMessage defaultMessage="5 clés" />
        </Text>

        <HStack
          spacing="0.25rem"
          backgroundColor="brandSecondary.300"
          borderRadius="full"
          color="#fff"
          py="4px"
          px="6px">
          <Icon icon="sf_key" />

          <Icon icon="sf_key" />

          <Icon icon="sf_key" />

          <Icon icon="sf_key" />

          <Icon icon="sf_key" />
        </HStack>
      </HStack>
    ),
  },
];

export const RankingPicker = forwardRef(
  /**
   * @param {import("@raiden/library-ui/components/ReactHookForm/FormControlRHF").FieldProps<import("../../../types/Search").SearchRanking>} props
   */
  function RankingPicker({ name, value, onChange, isDisabled }, ref) {
    const currentOption = useMemo(
      () => OPTIONS.find((option) => option.value === value),
      [value],
    );

    const previousOption = useMemo(() => {
      const index = OPTIONS.findIndex((option) => option.value === value);
      return OPTIONS[index - 1];
    }, [value]);

    const nextOption = useMemo(() => {
      const index = OPTIONS.findIndex((option) => option.value === value);
      return OPTIONS[index + 1];
    }, [value]);

    return (
      <Box sx={{ containerType: "inline-size" }} overflow="hidden">
        <Box
          sx={{
            [`@container (width >= 760px)`]: {
              "&": {
                display: "none",
              },
            },
          }}>
          <HStack justifyContent="space-between" spacing=".5rem">
            <Button
              onClick={() => {
                onChange({ target: { name, value: previousOption?.value } });
              }}
              isDisabled={!previousOption || isDisabled}
              variant="outline"
              borderRadius="full">
              -
            </Button>

            <Box
              minW="5rem"
              opacity={isDisabled ? 0.5 : 1}
              color={value > 0 ? undefined : "gray.500"}
              fontSize="1rem">
              {currentOption && <currentOption.component />}
            </Box>

            <Button
              onClick={() => {
                onChange({ target: { name, value: nextOption?.value } });
              }}
              isDisabled={!nextOption || isDisabled}
              variant="outline"
              borderRadius="full">
              +
            </Button>
          </HStack>
        </Box>

        <HStack
          sx={{
            [`@container (width < 760px)`]: {
              "&": {
                display: "none",
              },
            },
          }}>
          {OPTIONS.map((option) => (
            <Button
              key={option.value}
              ref={ref}
              onClick={() =>
                onChange({
                  target: {
                    name,
                    value: value === option.value ? "" : option.value,
                  },
                })
              }
              isDisabled={isDisabled}
              colorScheme={
                value === option.value ? "brandSecondary" : undefined
              }
              size="sm"
              borderRadius="full"
              minW="2.5rem"
              h="2.5rem"
              variant="outline">
              <Box color="inherit" fontSize="0.75rem" fontWeight={400}>
                <option.component />
              </Box>
            </Button>
          ))}
        </HStack>
      </Box>
    );
  },
);
