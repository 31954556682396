import { Stack, Text } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Place } from "./Place";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Place.js").Place | null} place
 * @property {string} term
 * @property {import("@raiden/library-ui/types/PlaceAutocomplete.js").PlaceAutocomplete[]} placesAutocomplete
 * @property {import(".").PlacesSearchGetPlaceCallback} getPlace
 */

/**
 * @param {Props} props
 */
export function PlacesSearchResults({
  place,
  term,
  placesAutocomplete,
  getPlace,
}) {
  const cities = useMemo(() => {
    return placesAutocomplete.filter((place) => place.type === "city") ?? [];
  }, [placesAutocomplete]);

  const departments = useMemo(() => {
    return (
      placesAutocomplete.filter((place) => place.type === "department") ?? []
    );
  }, [placesAutocomplete]);

  const placesToVisit = useMemo(() => {
    return (
      placesAutocomplete.filter(
        (place) =>
          place.type === "point_of_interest" ||
          place.type === "tourist_attraction",
      ) ?? []
    );
  }, [placesAutocomplete]);

  const otherPlaces = useMemo(() => {
    return (
      placesAutocomplete.filter(
        (place) =>
          [
            "city",
            "department",
            "point_of_interest",
            "tourist_attraction",
          ].includes(place.type) === false,
      ) ?? []
    );
  }, [placesAutocomplete]);

  const handleClickPlace = useCallback(
    /** @type {import("./Place").PlaceAutocompleteOnClickCallback} */
    ({ placeAutocomplete }) => {
      getPlace({ placeId: placeAutocomplete.id });
    },
    [getPlace],
  );

  return (
    <>
      <Stack spacing="0.75rem">
        {departments.length > 0 && (
          <>
            <Text variant="text-input">
              <FormattedMessage defaultMessage="Départements" />
            </Text>

            <Stack spacing="0.25rem">
              {departments.map((placeAutocomplete, index) => (
                <Place
                  id={`places-search-result-${index}`}
                  place={place}
                  placeAutocomplete={placeAutocomplete}
                  term={term}
                  onClick={handleClickPlace}
                  key={placeAutocomplete.id}
                />
              ))}
            </Stack>
          </>
        )}

        {cities.length > 0 && (
          <>
            <Text variant="text-input">
              <FormattedMessage defaultMessage="Villes" />
            </Text>

            <Stack spacing="0.25rem">
              {cities.map((placeAutocomplete, index) => (
                <Place
                  id={`places-search-result-${index + departments.length}`}
                  place={place}
                  placeAutocomplete={placeAutocomplete}
                  term={term}
                  onClick={handleClickPlace}
                  key={placeAutocomplete.id}
                />
              ))}
            </Stack>
          </>
        )}

        {placesToVisit.length > 0 && (
          <>
            <Text variant="text-input">
              <FormattedMessage defaultMessage="À visiter" />
            </Text>

            <Stack spacing="0.25rem">
              {placesToVisit.map((placeAutocomplete, index) => (
                <Place
                  id={`places-search-result-${
                    index + cities.length + cities.length
                  }`}
                  place={place}
                  placeAutocomplete={placeAutocomplete}
                  term={term}
                  onClick={handleClickPlace}
                  key={placeAutocomplete.id}
                />
              ))}
            </Stack>
          </>
        )}

        {otherPlaces.length > 0 && (
          <>
            <Text variant="text-input">
              <FormattedMessage defaultMessage="Autres" />
            </Text>

            <Stack spacing="0.25rem">
              {otherPlaces.map((placeAutocomplete, index) => (
                <Place
                  id={`places-search-result-${
                    index +
                    cities.length +
                    departments.length +
                    placesToVisit.length
                  }`}
                  place={place}
                  placeAutocomplete={placeAutocomplete}
                  term={term}
                  onClick={handleClickPlace}
                  key={placeAutocomplete.id}
                />
              ))}
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
}
