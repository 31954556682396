import { REGIONS } from "../../constants/regions";

/**
 * @param {object} params
 * @param {string} params.code
 * @returns {import("../../types/Region").RegionFront | undefined}
 */
export function getRegionByCode({ code }) {
  return REGIONS.find((department) => department.code === code);
}
