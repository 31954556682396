import { Box, Text } from "@chakra-ui/react";

/**
 * @typedef {object} Props
 * @property {string} title
 * @property {import("react").ReactNode} children
 */
/**
 * @param {Props} props
 */
export function Section({ title, children }) {
  return (
    <Box>
      <Text variant="h5">{title}</Text>

      <Box mt="1rem">{children}</Box>
    </Box>
  );
}
