import { getDepartmentByCode } from "../departments/getDepartmentByCode";
import { getRegionByCode } from "../regions/getRegionByCode";

/**
 * @param {object} params
 * @param {import("react-intl").IntlShape} params.intl
 * @param {import("@raiden/library-ui/types/Configuration").Configuration} params.configuration
 * @param {import("../../containers/Places/Search").PlacesSearchValue} params.value
 * @param {import("@raiden/library-ui/types/Campaign").Campaign} [params.campaign]
 * @returns {string | undefined}
 */
export function getSearchPlaceLabel({ intl, configuration, value, campaign }) {
  const region = getRegionByCode({
    code: value.region_code,
  });
  const department = getDepartmentByCode({
    code: value.department_code,
    configuration,
  });
  return campaign?.localization
    ? campaign.localization.title ??
        intl.formatMessage({
          defaultMessage: "Votre destination",
        })
    : value.map
    ? intl.formatMessage({
        defaultMessage: "Recherche à partir de la carte",
      })
    : value.place
    ? value.place.name
    : department
    ? department.name
    : region
    ? intl.formatMessage(region.name)
    : undefined;
}
