import {
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef } from "react";

const POPOVER_CONTENT_DEFAULT_PROPS = {};

/**
 * @param {...Partial<import("@chakra-ui/react").InputProps>} propsArgs
 * @returns {Partial<import("@chakra-ui/react").InputProps>}
 */
export function mergeProps(...propsArgs) {
  /** @type {Partial<import("@chakra-ui/react").InputProps>} */
  const mergedProps = {};

  // merge all props as usual
  for (const props of propsArgs) {
    Object.assign(mergedProps, props);
  }

  const keysFunctions = new Set();

  for (const props of propsArgs) {
    for (const key in props) {
      if (typeof props[key] === "function") {
        keysFunctions.add(key);
      }
    }
  }

  // merge functions together
  for (const key of keysFunctions) {
    mergedProps[key] = (...args) => {
      for (const props of propsArgs) {
        if (props[key]) {
          props[key](...args);
        }
      }
    };
  }

  return mergedProps;
}

/**
 * @typedef {object} Props
 * @property {boolean} isOpen
 * @property {() => void} onOpen
 * @property {() => void} onClose
 * @property {(params: {inputProps: {onClick: () => void, onFocus: (event: import("react").FocusEvent<HTMLInputElement, Element>) => void, onBlur: (event: import("react").FocusEvent<HTMLInputElement, Element>) => void, onKeyDown: (event: import("react").KeyboardEvent<HTMLInputElement>) => void}, onClose: () => void}) => import("react").ReactNode} renderInput
 * @property {import("react").ReactNode} popoverBody
 * @property {Partial<import("@chakra-ui/react").PopoverContentProps>} [popoverContentProps]
 */
/**
 * @param {Props} props
 */
export function PopoverInput({
  isOpen: _isOpen,
  onOpen: _onOpen,
  onClose: _onClose,
  renderInput,
  popoverBody,
  popoverContentProps = POPOVER_CONTENT_DEFAULT_PROPS,
}) {
  const {
    isOpen: isOpenDisclosure,
    onOpen: onOpenDisclosure,
    onClose: onCloseDisclosure,
  } = useDisclosure();

  const isOpen = _isOpen ?? isOpenDisclosure;
  const onOpen = _onOpen ?? onOpenDisclosure;
  const onClose = _onClose ?? onCloseDisclosure;

  const popoverContentRef = useRef(/** @type {HTMLDivElement | null} */ (null));

  function handleBlur(e) {
    // if the new element focus is inside the popover, don't close it
    if (popoverContentRef.current?.contains(e.relatedTarget)) {
      return;
    } else {
      onClose();
    }
  }

  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      autoFocus={false}
      placement="bottom-start"
      isLazy={true}
      lazyBehavior="unmount">
      <PopoverAnchor>
        {renderInput({
          onClose,
          inputProps: {
            onClick: onOpen,
            onFocus: onOpen,
            onBlur: handleBlur,
            onKeyDown: (e) => {
              if (e.key === "Escape") {
                onClose();
              } else {
                onOpen();
              }
            },
          },
        })}
      </PopoverAnchor>
      <PopoverContent ref={popoverContentRef} w="auto" {...popoverContentProps}>
        <PopoverBody p="0">{popoverBody}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
