import { getCampaignAttribute } from "../getAttribute";

/**
 * @param {object} params
 * @param {import("../../../types/Campaign").Campaign} params.campaign
 * @param {import("../../../types/Campaign").CampaignAttributeName[]} params.attributeNamesToCheck
 * @returns {boolean}
 */
export function isAnyAttributeSetInCampaign({
  campaign,
  attributeNamesToCheck,
}) {
  return attributeNamesToCheck.some((name) => {
    const value = getCampaignAttribute({ campaign, name });
    return value !== undefined && value !== null;
  });
}
