import { Button, Text, Wrap } from "@chakra-ui/react";
import { forwardRef, useMemo } from "react";
import { FormattedDate } from "react-intl";
import { getAvailableMonths } from "../../../helpers/search/getAvailableMonths";

export const MonthPicker = forwardRef(
  /**
   * @param {import("@raiden/library-ui/components/ReactHookForm/FormControlRHF").FieldProps<string>} props
   */
  function MonthPicker({ name, value, onChange }, ref) {
    const months = useMemo(() => {
      return getAvailableMonths();
    }, []);

    return (
      <Wrap>
        {months.map((month, index) => (
          <Button
            ref={index === 0 ? ref : undefined}
            onClick={() =>
              onChange({
                target: { name, value: value === month ? "" : month },
              })
            }
            size="sm"
            variant="outline"
            borderRadius="full"
            colorScheme={value === month ? "brandSecondary" : undefined}
            key={index}>
            <Text variant="text-xs" color="inherit" textTransform="capitalize">
              <FormattedDate value={month} month="long" year="numeric" />
            </Text>
          </Button>
        ))}
      </Wrap>
    );
  },
);
