import { Button, Text, Wrap } from "@chakra-ui/react";
import { forwardRef } from "react";
import { useIntl } from "react-intl";
import { SEARCH_DURATIONS_LIST } from "../../../constants/Search";

export const DurationPicker = forwardRef(
  /**
   * @param {import("@raiden/library-ui/components/ReactHookForm/FormControlRHF").FieldProps<import("../../../types/Search").SearchDuration>} props
   */
  function DurationPicker({ name, value, onChange }, ref) {
    const intl = useIntl();

    return (
      <Wrap>
        {SEARCH_DURATIONS_LIST.map((duration, index) => (
          <Button
            ref={index === 0 ? ref : undefined}
            variant="outline"
            onClick={() => {
              onChange({
                target: { name, value: duration.value },
              });
            }}
            borderRadius="full"
            colorScheme={
              value === duration.value ? "brandSecondary" : undefined
            }
            key={duration.value}>
            <Text variant="text-xs" color="inherit">
              {intl.formatMessage(duration.label)}
            </Text>
          </Button>
        ))}
      </Wrap>
    );
  },
);
