import { useRouter } from "next/router";
import { useMemo } from "react";
import { getNumber } from "./useRouterNumber";

/**
 * @param {any} value
 * @returns {number[] | undefined}
 */
export function getNumberArray(value) {
  /** @type {number[]} */
  const array = [];
  if (Array.isArray(value)) {
    value.forEach((v) => {
      const number = getNumber(v);
      if (number !== undefined) {
        array.push(number);
      }
    });
    return array;
  }
  return undefined;
}

/**
 * @param {string} key
 * @returns {number[] | undefined}
 */
export function useRouterNumberArray(key) {
  const router = useRouter();

  const value = useMemo(() => {
    const value = router.query[key];
    return getNumberArray(value);
  }, [key, router.query]);

  return value;
}
