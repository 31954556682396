import { useRouter } from "next/router";
import { useMemo } from "react";

/**
 * @param {any} value
 */
export function getStringArray(value) {
  /** @type {string[]} */
  if (Array.isArray(value)) {
    return value;
  }
  if (typeof value === "string") {
    return [value];
  }
  return undefined;
}

/**
 * @param {string} key
 * @returns {string[] | undefined}
 */
export function useRouterStringArray(key) {
  const router = useRouter();

  const value = useMemo(() => {
    const value = router.query[key];
    return getStringArray(value);
  }, [key, router.query]);

  return value;
}
